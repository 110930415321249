import React, { useState } from 'react';

const Prompt = ({ question, yes, cancel, input }) => {

	const [inputValue, setInputValue] = useState('');

	return (
		<div className='absolute top-0 left-0 z-50 h-full w-full backdrop-blur-sm bg-black/25'>
			<div className='relative flex items-center justify-center h-full'>
				<div id='dialog' className='relative rounded-md z-50 w-1/3 border-2 border-white/25 bg-[#1B1B1B] flex items-start gap-2'>
					<div className='flex flex-col justify-between p-4 gap-3 w-full'>

						{/* QUESTION */}
						<div className='flex items-center justify-between text-xl'>
							{question}
						</div>

						{input && <div className='input-container-small'>
							<input type="text" placeholder={input} value={inputValue} onChange={(e) => {
								setInputValue(e.target.value)
							}} />
						</div>}

						{/* BUTTONS */}
						<div className='flex items-center justify-end gap-3'>
							<div className='button-secondary' onClick={(e) => {
								yes(inputValue)
								cancel()
							}}>Confirmer</div>
							<div className='button-secondary' onClick={cancel}>Annuler</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Prompt;
