import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import SnackbarSeance from "./utils/snackbar-seance-now";
import { useAuth } from "./auth";
import { getAPI, postAPI, sendNotification } from "./utils/functions";
import ValidationSeance from "./utils/validation-seance";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useSnackbar } from "notistack";
import Dialog from "./utils/dialog";
import ReviewPost from "./utils/review_post";

const SeanceLoader = ({ socket }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const auth = useAuth();
	const [seance, setSeance] = useState(null);
	const [snackSeance, setSnackSeance] = useState(null);
	const [showSeance, setShowSeance] = useState(false);
	const [context, setContext] = useState({});
	const [timeLeft, setTimeLeft] = useState(null);
	const [dialogContent, setDialogContent] = useState(null);
	const [validated, setValidated] = useState(false);
	const [seanceStarted, setSeanceStarted] = useState(false);
	const inter = useRef(null);
	const [endedSeance, setEndedSeance] = useState(null);

	useEffect(() => {
		if (!auth.user.id) {
			setShowSeance(null);
			setSeanceStarted(null);
			setContext({});
			setSeance(null);
		}
		if (auth.user.id) {
			getAPI(`/user/seances/now/`).then((dat) => {
				if (dat.length >= 1) {
					setSeance(dat);
				}
			});
		}
	}, [auth]);

	useEffect(() => {
		if (!socket) return;
		if (!auth.user.id) return;

		socket.emit("online", auth.user.id);

		socket.on("starting-seance", (seance_s) => {
			if (
				seance_s.member.toString() !==
					localStorage.getItem("id").toString() &&
				seance_s.coach.toString() !==
					localStorage.getItem("id").toString()
			) {
				return;
			}
			setSeance(seance_s);
		});

		socket.on("validated", async ({ id }) => {
			if (id == auth.user.id) {
				setValidated(true);
				await postAPI(`/user/addPoints`, {
					points: 5,
					id: auth.user.id,
				});
			}
			setContext((prev) => {
				let newObj = { ...prev };
				newObj[id] = true;
				return newObj;
			});
		});

		socket.on("cancelling-seance", async ({ seance_s, reason }) => {
			if (
				seance_s.member.id.toString() !==
					localStorage.getItem("id").toString() &&
				seance_s.coach.id.toString() !==
					localStorage.getItem("id").toString()
			) {
				return;
			}

			if (reason === "not-validated-on-time") {
				setContext((prev) => {
					if (prev.length === 0) {
						sendNotification(
							seance_s.member.id,
							`La séance a été annulée car personne n'a validé sa présence à temps`,
							"profile#seances",
						);
						sendNotification(
							seance_s.coach.id,
							`La séance a été annulée car personne n'a validé sa présence à temps`,
							"profile#seances",
						);
						enqueueSnackbar(
							`La séance a été annulée car personne n'a validé sa présence à temps`,
							{ variant: "info" },
						);
					}
					Object.entries(prev).forEach((e) => {
						if (
							e[0] !== seance_s.member.id.toString() &&
							e[0] !== seance_s.coach.id.toString()
						) {
							sendNotification(
								e[0],
								`La séance a été annulée car vous n'avez pas validé votre présence à temps`,
								"profile#seances",
							);
							enqueueSnackbar(
								`La séance a été annulée car vous n'avez pas validé votre présence à temps`,
								{ variant: "info" },
							);
						}
						if (e[1] === true) {
							sendNotification(
								e[0],
								`La séance a été annulée car l'autre membre n'a pas validé sa présence à temps`,
								"profile#seances",
							);
							enqueueSnackbar(
								`La séance a été annulée car l'autre membre n'a pas validé sa présence à temps`,
								{ variant: "info" },
							);
						}
					});
					return prev;
				});
				setContext({});
			}
			setSeance(null);
		});

		socket.on("ending-seance", async (seance_s) => {
			if (
				seance_s.member.toString() !==
					localStorage.getItem("id").toString() &&
				seance_s.coach.toString() !==
					localStorage.getItem("id").toString()
			) {
				return;
			}

			setSeance((prev) => {
				if (!prev) return;
				if (auth.user.id === prev.member.id) {
					enqueueSnackbar(
						`La séance est terminée ! Envie de laisser un avis ? Rendez-vous dans votre espace notifications`,
						{ variant: "success" },
					);
				} else {
					enqueueSnackbar(`La séance est terminée !`, {
						variant: "success",
					});
				}
				setEndedSeance(prev);
				sendNotification(
					prev.member.id,
					`Votre séance est terminée. Faites savoir à ${prev.coach.pseudo} ce que vous en avez pensé en laissant un avis`,
					"profile#seances",
				);
				sendNotification(
					prev.coach.id,
					`Votre séance avec ${prev.member.pseudo} est terminée. Félicitations !`,
					"profile#seances",
				);
				return null;
			});
		});

		return () => {
			socket.disconnect();
			socket.removeAllListeners();
		};
	}, [socket, auth]);

	useEffect(() => {
		if (verifyContext()) setSeanceStarted(true);
	}, [context]);

	const verifyContext = () => {
		return (
			Object.values(context).every((v) => v === true) &&
			Object.keys(context).length === 2
		);
	};

	useEffect(() => {
		if (!seanceStarted) return;

		clearInterval(inter.current);
		setSnackSeance(
			`Votre séance avec ${auth.user.coach == 1 ? seance.member.pseudo : seance.coach.pseudo} est en cours. Cliquez ici pour accéder à la séance.`,
		);
	}, [seanceStarted]);

	useEffect(() => {
		if (!seance) return;
		socket.emit("seance-started", { seance: seance, client: auth.user.id });

		// AFFICHER LA VALIDATION DE SEANCE
		if (
			moment(seance.date)
				.utc()
				.add(seance.isDelayed === 1 ? 30 : 1, "minute") > moment().utc()
		) {
			setSnackSeance(
				`Votre séance avec ${auth.user.coach == 1 ? seance.member.pseudo : seance.coach.pseudo} commence. Cliquez ici pour accéder à la séance.`,
			);
			//console.log(`La séance n°${seance.id} commence`);
			inter.current = setInterval(() => {
				setTimeLeft(
					moment(seance.date)
						.add(seance.isDelayed === 1 ? 30 : 15, "minute")
						.fromNow(true),
				);
				setSeanceStarted((prev) => {
					//console.log(moment(seance.date).add(seance.isDelayed === 1 ? 30 : 15, 'minute'), moment());
					if (
						moment(seance.date)
							.utc()
							.add(seance.isDelayed === 1 ? 30 : 1, "minute") <=
							moment().utc() &&
						!prev
					) {
						socket.emit("not-validated-on-time", seance);
						setShowSeance(false);
						clearInterval(inter.current);
					}
					return prev;
				});
			}, 1000);
		}
		return () => {
			clearInterval(inter.current);
		};
	}, [seance]);

	const handleCloseDialog = () => {
		setDialogContent(null);
	};

	const refreshDialog = () => {
		console.error(`[SEANCE_LOADER] => Unable to refresh recap`);
	};

	return (
		<span id="seance-loader">
			{seance && showSeance && !seanceStarted && (
				<span
					id="seance-starting"
					className="absolute top-0 left-0 z-40 h-full w-full backdrop-blur-md bg-black/25 pt-20"
				>
					<span className="relative flex items-start justify-center h-full">
						<div className="relative rounded-md z-50 w-1/2 bg-[#0E1210] bg-opacity-90 border-1 border-white/10 flex flex-col items-center gap-6 p-3 pb-6 shadow-md">
							<div className="head flex flex-col justify-center items-center w-full py-2 gap-3">
								<div className="flex items-center justify-between w-full">
									<h1 className="text-3xl font-bold grow text-center">
										Votre séance commence !
									</h1>
									<h1
										className="absolute right-2 top-2 p-2 rounded-full hover:bg-white/5 ease-in-out duration-150 cursor-pointer"
										onClick={(e) => {
											setShowSeance(false);
										}}
									>
										<CloseRoundedIcon />
									</h1>
								</div>
								<div className="h-[2px] rounded bg-primary w-2/3"></div>
							</div>
							<div className="content flex justify-center gap-16 w-[80%]">
								<div className="left w-fit">
									<ValidationSeance
										user={seance.coach}
										context={context}
									/>
								</div>
								<div className="separator h-auto border rounded border-white/25"></div>
								<div className="right w-fit">
									<ValidationSeance
										user={seance.member}
										context={context}
									/>
								</div>
							</div>
							<div className="flex flex-col gap-3">
								<div
									className="button-secondary"
									onClick={() => {
										setDialogContent(seance);
									}}
								>
									Voir le récapitulatif
								</div>
								{!validated && (
									<div
										className="gap-2 button-primary2 text-lg"
										onClick={() => {
											if (!socket) return;
											socket.emit(
												"validated",
												auth.user.id,
											);
											setValidated(true);
										}}
									>
										<h3>Valider ma présence</h3>
										<CheckCircleRoundedIcon />
									</div>
								)}
								<div>
									<h3 className="opacity-80">
										Temps restant pour valider votre
										présence : <strong>{timeLeft}</strong>
									</h3>
								</div>
							</div>
						</div>
					</span>
				</span>
			)}
			{snackSeance && seance && (
				<SnackbarSeance
					title={`Il est l'heure !`}
					text={snackSeance}
					onClick={(e) => {
						setShowSeance(true);
					}}
				/>
			)}
			{seanceStarted && showSeance && seance && (
				<span
					id="seance-starting"
					className="absolute top-0 left-0 z-40 h-full w-full backdrop-blur-md bg-black/25 pt-20"
				>
					<span className="relative flex items-start justify-center h-full">
						<div className="relative rounded-md z-50 w-1/2 bg-[#0E1210] bg-opacity-90 border-1 border-white/10 flex flex-col items-center gap-6 p-3 pb-6 shadow-md">
							<div className="head flex flex-col justify-center items-center w-full py-2 gap-3">
								<div className="flex items-center justify-between w-full">
									<h1 className="text-3xl font-bold grow text-center">
										Votre séance est en cours...
									</h1>
									<h1
										className="absolute right-2 top-2 p-2 rounded-full hover:bg-white/5 ease-in-out duration-150 cursor-pointer"
										onClick={(e) => {
											setShowSeance(false);
										}}
									>
										<CloseRoundedIcon />
									</h1>
								</div>
								<div className="h-[2px] rounded bg-primary w-2/3"></div>
							</div>
							<div className="content flex justify-center gap-16 w-[80%]"></div>
							<div className="flex flex-col gap-3">
								<div
									className="button-secondary"
									onClick={() => {
										setDialogContent(seance);
									}}
								>
									Voir le récapitulatif
								</div>
								<div>
									<h3 className="opacity-80">
										Heure prévue de fin de séance :{" "}
										<strong>
											{moment(seance.date)
												.add(
													seance.isDelayed === 1
														? 20
														: 5,
													"minute",
												)
												.add(1, "hour")
												.calendar()}
										</strong>
									</h3>
								</div>
							</div>
						</div>
					</span>
				</span>
			)}
			{endedSeance && (
				<span
					id="seance-starting"
					className="absolute top-0 left-0 z-40 h-full w-full backdrop-blur-md bg-black/25 pt-20"
				>
					<span className="relative flex items-start justify-center h-full">
						<div className="relative rounded-md z-50 w-1/2 bg-[#0E1210] bg-opacity-90 border-1 border-white/10 flex flex-col items-center gap-8 p-3 pb-6 shadow-md">
							<div className="head flex flex-col justify-center items-center w-full py-2 gap-3">
								<div className="flex items-center justify-between w-full">
									<h1 className="text-3xl font-bold grow text-center">
										Votre séance est terminée !
									</h1>
									<h1
										className="absolute right-2 top-2 p-2 rounded-full hover:bg-white/5 ease-in-out duration-150 cursor-pointer"
										onClick={(e) => {
											setEndedSeance(null);
										}}
									>
										<CloseRoundedIcon />
									</h1>
								</div>
								<div className="h-[2px] rounded bg-primary w-2/3"></div>
							</div>
							{auth.user.id == endedSeance.member.id ? (
								<div className="content flex items-center flex-col gap-8 w-[80%]">
									<h3 className="text-2xl font-bold">
										Envie de laisser un avis ?
									</h3>
									<ReviewPost
										coach={endedSeance.coach.id}
										user={auth.user.id}
									/>
								</div>
							) : (
								<div className="content flex items-center flex-col gap-8 w-[80%]">
									<h3 className="text-2xl font-bold">
										{endedSeance.member.pseudo} peut
										maintenant laisser un avis sur votre
										profil
									</h3>
								</div>
							)}

							<div className="flex flex-col gap-3">
								<div
									className="button-secondary"
									onClick={() => {
										setDialogContent(endedSeance);
									}}
								>
									Voir le récapitulatif
								</div>
							</div>
						</div>
					</span>
				</span>
			)}
			{dialogContent && (
				<Dialog
					content={dialogContent}
					handleClose={handleCloseDialog}
					refreshFunction={refreshDialog}
				/>
			)}
		</span>
	);
};

export default SeanceLoader;
