import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

const HashManager = () => {
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (!window.location.hash) return;
		setMessage(window.location.hash.substring(1));
	}, []);

	useEffect(() => {
		switch (message) {
			case "jwt_expired":
				enqueueSnackbar("La session a expirée", { variant: "error" });
				break;

			case "jwt_not_provided":
				enqueueSnackbar("Vous devez vous connecter", {
					variant: "error",
				});
				break;

			default:
				break;
		}
	}, [message]);

	return <></>;
};
export default HashManager;
