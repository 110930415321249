import React from 'react';
import {Link} from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/fr';

class Home extends React.Component {
	

	componentDidMount() {
		var activeLink = document.querySelector('.active-link');
		activeLink ? activeLink.classList.remove('active-link') : activeLink = null;
		document.getElementById('home').classList.add('active-link')
		moment.locale('fr');
	}

	render() {
		return (
			<div className='home-container'>
				<div className='neon left-[-1000px] top-[-350px]'></div>
				<div className='neon right-[-1000px]'></div>


				<div className='main-panel align-center w-full text-center mt-[200px] flex flex-col justify-between items-center gap-8'>
					<h1 className='text-[60px] font-bold leading-[67px] w-[70%]'>Améliore-toi plus vite que jamais grâce à nos coachs</h1>
					<h3 className='text-xl w-[70%]'>Trouve ton coach parmis notre large sélection de coachs vérifiés. Rapide, efficace et sécurisé, SkillUpNow te permettera de trouver le coach idéal en seulement quelques minutes. Alors lance toi !</h3>
					<Link to={'/coachs'} className='button-primary-large'>Trouver mon coach</Link>
				</div>
			</div>
		);
	}
}

export default Home;