import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import StyleRoundedIcon from "@mui/icons-material/StyleRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { useAuth } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Prompt from "./prompt";
import { sendNotification, postAPI } from "./functions";
import ReviewPost from "./review_post";

const Dialog = ({ content, handleClose, refreshFunction }) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const recap = JSON.parse(content.recap);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [promptContent, setPromptContent] = useState({});

	let statusIcon;
	switch (content.status) {
		case "Validée":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#5E98FC" />
				</svg>
			);
			break;

		case "Annulée":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#ED4337" />
				</svg>
			);
			break;

		case "Refusée":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#ED4337" />
				</svg>
			);
			break;

		case "Terminée":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#52fc1a" />
				</svg>
			);
			break;

		case "En attente d'annulation":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#d1cb1d" />
				</svg>
			);
			break;

		case "En attente de confirmation":
			statusIcon = (
				<svg
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="5" cy="5" r="5" fill="#d1cb1d" />
				</svg>
			);
			break;

		default:
			break;
	}

	const cancelSeance = async () => {
		setPromptContent(() => {
			return {
				question: `Voulez vous vraiment annuler la séance n°${content.id} avec ${auth.user.coach == 1 ? content.member.pseudo : content.coach.pseudo} ?`,
				yes: async (reason) => {
					await postAPI(`/user/seances/cancelSeance`, {
						id: content.id,
						facture: content.facture,
						cancelReason: reason,
					}).then((dat) => {
						if (dat.affectedRows >= 1) {
							sendNotification(
								auth.user.id == content.coach.id
									? content.member.id
									: content.coach.id,
								`La séance n°${content.id} a été annulée par ${auth.user.pseudo}`,
								"profile#seances",
							);
							enqueueSnackbar(
								`Vous avez annulé la séance n°${content.id}`,
								{ variant: "info" },
							);
							refreshFunction();
						}
					});
				},
				cancel: () => {
					setPromptContent({});
				},
				input: "Raison (optionnel)",
			};
		});
	};

	const askCancelSeance = async () => {
		setPromptContent(() => {
			return {
				question: `La séance a lieu dans moins de 24h. Vous devez avoir l'accord de ${content.coach.pseudo} pour annuler la séance`,
				yes: async () => {
					await postAPI(`/user/seances/askCancelSeance`, {
						id: content.id,
					}).then((dat) => {
						if (dat.affectedRows >= 1) {
							sendNotification(
								content.coach.id,
								`${content.member.pseudo} à demandé l'annulation de la séance n°${content.id}`,
								"profile#cancel",
							);
							enqueueSnackbar(
								`Vous avez demandé l'annulation de la séance n°${content.id}`,
								{ variant: "info" },
							);
							refreshFunction();
						}
					});
				},
				cancel: () => {
					setPromptContent({});
				},
			};
		});
	};

	const delaySeance = async () => {
		await postAPI(`/delaySeance/${content.id}`, {
			date: content.date,
		}).then((dat) => {
			if (dat.affectedRows >= 1) {
				enqueueSnackbar(
					`Le retard a été signalé ! Vous aurez 15 minutes de plus pour valider votre présence.`,
					{ variant: "info" },
				);
				refreshFunction();
				content.isDelayed = 1;
			}
		});
	};

	const renderButtons = () => {
		if (moment(content.date) < moment()) {
			if (content.status === "Terminée") {
				if (auth.user.id == content.member.id) {
					return (
						<div className="flex flex-col gap-1">
							<h3 className="text-xl">Évaluez votre séance</h3>
							<ReviewPost
								coach={content.coach.id}
								user={content.member.id}
							/>
						</div>
					);
				} else {
					return (
						<div className="flex flex-col gap-2 opacity-75">
							<h3>
								La séance est terminée. {content.member.pseudo}{" "}
								peut laisser un avis.
							</h3>
						</div>
					);
				}
			}
			return (
				<div className="flex flex-col gap-2 opacity-75">
					<h3>Il n'est plus possible de modifier la séance</h3>
				</div>
			);
		}
		if (content.status === "Validée") {
			return (
				<div className="flex flex-col gap-2">
					<div className="w-fit">
						{!content.isDelayed ? (
							<div
								className="button-secondary flex flex-row items-center gap-2"
								onClick={delaySeance}
							>
								<WatchLaterRoundedIcon fontSize="medium" />
								Décaler la séance de 15 minutes
							</div>
						) : (
							<div className="flex items-center gap-1 opacity-75 text-blue-500">
								<InfoIcon />
								<h3>La séance a été retardée de 15 min</h3>
							</div>
						)}
					</div>
					<div className="w-fit">
						<div
							className="button-secondary flex flex-row items-center gap-2"
							onClick={async (e) => {
								await postAPI(`/user/addContact`, {
									contact:
										content.member.pseudo ===
										auth.user.pseudo
											? content.coach.id
											: content.member.id,
								}).then((res) => {
									navigate(`/chat`);
								});
							}}
						>
							<QuestionAnswerRoundedIcon fontSize="medium" />{" "}
							Contacter{" "}
							{content.member.pseudo === auth.user.pseudo
								? content.coach.pseudo
								: content.member.pseudo}
						</div>
					</div>
					{moment.utc(content.date) > moment().add(24, "hours") ? (
						<div className="w-fit" onClick={cancelSeance}>
							<div className="button-secondary flex flex-row items-center gap-2">
								<EventBusyRoundedIcon fontSize="medium" />{" "}
								Annuler la séance
							</div>
						</div>
					) : auth.user.coach == 1 ? (
						<div className="w-fit" onClick={cancelSeance}>
							<div className="button-secondary flex flex-row items-center gap-2">
								<EventBusyRoundedIcon fontSize="medium" />{" "}
								Annuler la séance
							</div>
						</div>
					) : (
						<div className="w-fit" onClick={askCancelSeance}>
							<div className="button-secondary flex flex-row items-center gap-2">
								<EventBusyRoundedIcon fontSize="medium" />{" "}
								Annuler la séance
							</div>
						</div>
					)}
				</div>
			);
		} else if (content.status === "Annulée") {
			return (
				<div className="flex flex-col gap-2 opacity-75">
					<h3>
						La séance est annulée pour la raison suivante :{" "}
						{content.cancelReason || "Pas de raison spécifiée"}
					</h3>
				</div>
			);
		} else if (content.status === "Refusée") {
			return (
				<div className="flex flex-col gap-2 opacity-75">
					<h3>La séance a été refusée par {content.coach.pseudo}</h3>
				</div>
			);
		} else if (content.status === "En attente de confirmation") {
			return (
				<div className="flex flex-col gap-2 opacity-75">
					<h3>
						La séance est en attente de confirmation par{" "}
						{content.coach.pseudo}
					</h3>
				</div>
			);
		} else if (content.status === "En attente d'annulation") {
			return (
				<div className="flex flex-col gap-2 opacity-75">
					<h3>
						Une demande d'annulation est en cours. Attente de{" "}
						{content.coach.pseudo}
					</h3>
				</div>
			);
		}
	};

	return (
		<div className="absolute top-0 left-0 z-40 h-full w-full backdrop-blur-sm bg-black/25">
			<div className="relative flex items-center justify-center h-full">
				<div
					id="dialog"
					className="relative rounded-md z-50 w-1/2 border-2 border-white/25 bg-[#1B1B1B] flex items-start gap-2"
				>
					<div className="bg-[#247BA0] flex flex-col gap-6 w-52">
						{content.member.pseudo === auth.user.pseudo ? (
							<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8 pt-4">
								<h3 className=" flex items-center gap-1">
									<SchoolRoundedIcon /> Coach :{" "}
								</h3>
								<Link
									to={`/coachs/${content.coach.pseudo}`}
									className="font-bold ml-6 cursor-pointer"
								>
									{content.coach.pseudo}
								</Link>
							</div>
						) : (
							<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8 pt-4">
								<h3 className=" flex items-center gap-1">
									<SchoolRoundedIcon /> Élève :{" "}
								</h3>
								<h4 className="font-bold ml-6">
									{content.member.pseudo}
								</h4>
							</div>
						)}
						<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8">
							<h3 className=" flex items-center gap-1">
								<CreditScoreRoundedIcon /> Montant :
							</h3>
							<h4 className="font-bold ml-6">
								{content.formule.prix.toFixed(2)}€
							</h4>
						</div>
						<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8">
							<h3 className=" flex items-center gap-1">
								<TodayRoundedIcon /> Date et heure :
							</h3>
							<h4 className="font-bold ml-6">
								{moment(content.date).format("ll")} <br />{" "}
								{moment(content.date).format("HH")}h
								{moment(content.date).format("mm")} -{" "}
								{moment(content.date)
									.add(content.durée, "hour")
									.format("HH")}
								h
								{moment(content.date)
									.add(content.durée, "hour")
									.format("mm")}
							</h4>
						</div>
						<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8">
							<h3 className=" flex items-center gap-1">
								<StyleRoundedIcon /> Formule :
							</h3>
							<h4 className="font-bold ml-6">
								{content.formule.titre} (
								{content.formule.heures_f}h)
							</h4>
						</div>
						<div className="flex flex-col border-b-2 border-white/25 pb-4 pl-6 pr-8">
							<h3 className=" flex items-center gap-1">
								<DescriptionRoundedIcon /> ID de la séance :
							</h3>
							<h4 className="font-bold ml-6">{content.id}</h4>
						</div>
						<div className="flex flex-col pl-6 pr-8 pb-4">
							<h3 className=" flex items-center gap-1">
								<DescriptionRoundedIcon /> Facture :
							</h3>
							<h4 className="font-bold ml-6 overflow-hidden break-words whitespace-pre-line">
								{content.facture}
							</h4>
						</div>
					</div>
					<div className="flex flex-col gap-4 p-4 w-full">
						<div className="flex items-center justify-between gap-6 grow">
							<h2 className="font-bold font-sf-pro-e text-lg">
								Informations relatives au joueur
							</h2>
							<div
								className="cursor-pointer"
								onClick={handleClose}
							>
								<CloseRoundedIcon fontSize="large" />
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<div className="mb-4 flex flex-wrap gap-5">
								{" "}
								{/* INFORMATIONS */}
								<div>
									<strong>Pseudo</strong>
									<h3>{recap.pseudo}</h3>
								</div>
								<div>
									<strong>Rang</strong>
									<h3>{recap.rank}</h3>
								</div>
								<div>
									<strong>Rôle(s)</strong>
									<h3>{recap.role}</h3>
								</div>
								<div>
									<strong>Heures de jeu</strong>
									<h3>{recap.heures}</h3>
								</div>
								<div>
									<strong>Mode de jeu</strong>
									<h3>{recap.gamemode}</h3>
								</div>
								<div>
									<strong>Point faible</strong>
									<h3>{recap.faible}</h3>
								</div>
								<div>
									<strong>Point fort</strong>
									<h3>{recap.fort}</h3>
								</div>
								<div>
									<strong>Routine d'entraînement</strong>
									<h3>{recap.routine}</h3>
								</div>
								<div>
									<strong>Attentes</strong>
									<h3>{recap.attentes}</h3>
								</div>
							</div>

							<h2 className="font-bold font-sf-pro-e text-lg">
								Informations relatives à la séance
							</h2>
							<div className="flex gap-1 items-baseline opacity-75">
								{" "}
								{statusIcon}{" "}
								<span className="font-bold">
									{content.status}
								</span>
							</div>
							{renderButtons()}
						</div>
					</div>
				</div>
			</div>
			{promptContent.question && (
				<Prompt
					question={promptContent.question}
					yes={promptContent.yes}
					cancel={promptContent.cancel}
					input={promptContent.input}
				/>
			)}
		</div>
	);
};

export default Dialog;
