// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-snow {
	filter: invert(1);
	color: white;
}

.ql-container {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	color: black;
	font-size: large;
} 

.ql-toolbar {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/editor.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,8BAA8B;CAC9B,+BAA+B;CAC/B,YAAY;CACZ,gBAAgB;AACjB;;AAEA;CACC,2BAA2B;CAC3B,4BAA4B;AAC7B","sourcesContent":[".ql-snow {\n\tfilter: invert(1);\n\tcolor: white;\n}\n\n.ql-container {\n\tborder-bottom-left-radius: 4px;\n\tborder-bottom-right-radius: 4px;\n\tcolor: black;\n\tfont-size: large;\n} \n\n.ql-toolbar {\n\tborder-top-left-radius: 4px;\n\tborder-top-right-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
