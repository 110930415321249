import React, { useEffect, useLayoutEffect, useState } from "react";
import moment from "moment";
import "./calendar.css";

import CircularProgress from "@mui/material/CircularProgress";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import { useAuth } from "../auth";
import Dialog from "../utils/dialog";
import { getAPI, postAPI } from "../utils/functions";

const FuturesSeances = () => {
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [seances, setSeances] = useState([]);
	const [events, setEvents] = useState([]);
	const [dialogContent, setDialogContent] = useState(null);

	useEffect(() => {
		auth.authenticate().then((r) => setUser(r));
	}, []);

	const fetchData = async () => {
		if (user) {
			await getAPI(`/user/seances/all`).then(async (dat) => {
				setSeances(dat);
				setLoading(false);
			});
			setDialogContent(null);
		}
	};

	useEffect(() => {
		fetchData();
	}, [user]);

	useEffect(() => {
		if (seances && seances.length > 0) {
			loadEvents();
		}
	}, [seances]);

	const loadEvents = () => {
		const ev = [
			Object.values(seances).map((v) => {
				return {
					id: v.id,
					title: v.formule.titre,
					start: moment(v.date).format("YYYY-MM-DD HH:mm:ss"),
					allDay: false,
				};
			}),
		];
		setEvents(ev);
	};

	useLayoutEffect(() => {
		if (seances.length) {
			let calendar = new Calendar(document.getElementById("calendar"), {
				plugins: [dayGridPlugin, timeGridPlugin],
				locale: "fr",
				initialView: "dayGridMonth",
				headerToolbar: {
					left: "title",
					center: "dayGridMonth,timeGridWeek",
					right: "prev today next",
				},
				buttonText: {
					today: "Aujourd'hui",
					month: "Mois",
					week: "Semaine",
					day: "Jour",
					list: "Liste",
				},
				eventSources: events,
				eventMouseEnter: () => {
					document.body.style.cursor = "pointer";
				},
				eventMouseLeave: () => {
					document.body.style.cursor = "auto";
				},
				eventClick: (info) => {
					let ret = Object.values(seances).find(
						(v) => v.id.toString() === info.event.id,
					);
					setDialogContent(ret);
				},
			});
			calendar.render();
		}
	}, [seances, events]);

	const handleCloseDialog = () => {
		setDialogContent(null);
	};

	if (loading)
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Séances à venir
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances à venir, validées ou
						non
					</p>
				</div>
				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
			</div>
		);

	if (seances && seances.length > 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Séances à venir
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances
					</p>
				</div>

				<div id="calendar" className="calendar"></div>

				{dialogContent && (
					<Dialog
						content={dialogContent}
						handleClose={handleCloseDialog}
						refreshFunction={fetchData}
					/>
				)}
			</div>
		);
	} else if (seances && seances.length === 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Séances à venir
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances à venir, validées ou
						non
					</p>
				</div>

				<div
					className={`border-2 border-white/25 bg-[#1B1B1B] rounded relative p-4`}
				>
					<div className="text-center">
						<h3 className="text-xl">
							Aucune séance à venir pour le moment...
						</h3>
					</div>
				</div>
			</div>
		);
	}
};

export default FuturesSeances;
