import React, {useEffect, useLayoutEffect, useState} from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "./avatar";
import {Send} from "@mui/icons-material";
import {useAuth} from "../auth";
import moment from "moment";
import {postAPI} from "./functions";

const Chat = ({ openChat, socket }) => {

    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [existingMessages, setExistingMessages] = useState([]);

    useEffect(() => {
        if (!socket) return;

        postAPI(`/getMessagesHist`, {
            sender: auth.user.id,
            target: openChat.id
        }).then((res) => {
            setExistingMessages(res.map((m) => {
                return {
                    sender: m.expediteur.toString(), target: m.destinataire.toString(), content: m.message, timestamp: m.date
                }
            }))
        })

        socket.on('chat message', (messageObject) => {
            //console.log(`Message reçu de ${messageObject.sender}`);
            if (messageObject.sender !== openChat.id.toString()) return;
            let v = existingMessages;
            v.push(messageObject);
            setExistingMessages(v);
        })
        setIsLoading(false);
    }, [])

    useEffect(() => {
        /* Login on enter handler */
        document.addEventListener('keyup', (e) => {
            if (e.key === 'Enter') {
                handleMessage();
            }
        });
    }, [])

    /* Au changement de chat, auto-focus l'input */
    useLayoutEffect(() => {
        if (isLoading) return;
        document.getElementById('message-input').focus();
    }, [openChat, isLoading]);

    useEffect(() => {
        setIsLoading(true);
        postAPI(`/getMessagesHist`, {
            sender: auth.user.id,
            target: openChat.id
        }).then((res) => {
            setExistingMessages(res.map((m) => {
                return {
                    sender: m.expediteur.toString(), target: m.destinataire.toString(), content: m.message, timestamp: m.date
                }
            }))
        })
        setIsLoading(false);
    }, [openChat]);

    const handleMessage = async () => {
        if (message.length < 1) return;

        //console.log(`Message envoyé`);

        let mObject = {
            sender: auth.user.id.toString(),
            target: openChat.id.toString(),
            content: message,
            timestamp: Date.now()
        };

        let v = existingMessages;
        v.push(mObject);
        setExistingMessages(v);

        socket.emit('chat message', mObject);

        await postAPI(`/addMessage`, mObject).then((res) => {
            if (res.success) {
                console.log(`en bdd`)
            } else {
                console.log(`mmh soucis`)
            }
        });

        setMessage(``);
    }

    if (isLoading) return (
        <div className='flex flex-col gap-5 w-full items-center absolute top-1/3 scale-125'>
            <div className='flex items-center justify-center mt-8'><CircularProgress color="success" /></div>
        </div>
    )

    return (
        <span id={`chat`} className={`flex flex-col w-full min-h-96`}>
            <div id={`chat-header`} className='flex items-center justify-between w-full border-white/25 border-b-2 py-4 px-6 mx-4'>
                <div className={`flex gap-4 items-center`}>
                    <Avatar image={openChat.image} size={50} radius={`rounded-full`} />
                    <h2 className={`text-2xl font-bold`}>{openChat.pseudo}</h2>
                </div>
            </div>

            <div id={`chat-content`} className={`min-h-96 h-[70vh] w-full flex flex-col gap-7 py-5 px-12 overflow-y-scroll`}>
                {
                    existingMessages.map((message) => {
                        return (
                            <span className={`flex flex-col gap-1 rounded-2xl ${message.sender === auth.user.id ? 'ml-auto bg-gray-800 rounded-br-none' : 'bg-secondary rounded-bl-none'} w-fit p-3`} key={message.timestamp}>
                                <div className={`text-lg break-all w-fit`}>
                                    {message.content}
                                </div>
                                <div className={`opacity-75 text-sm`}>
                                    {moment(message.timestamp).format('LT')}
                                </div>
                            </span>
                        )
                    })
                }
            </div>

            <div id={`chat-action`} className='flex gap-4 justify-between items-center w-full py-3 px-4 rounded'>
                <div className='input-container'>
					<input className='m-0' autoFocus={true} value={message} placeholder='Écrivez un message ...' type="text" name="message-input" id="message-input" onChange={(e) => setMessage(e.target.value)} />
				</div>
                <div id={`submit-message`} className={`bg-secondary rounded-full pl-5 pr-4 pt-4 pb-5 hover:cursor-pointer hover:opacity-75`} onClick={(e) => {
                    handleMessage()
                }}>
                    <Send />
                </div>
            </div>
        </span>
    )
}
export default Chat