import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";

export const useStripeConnect = (connectedAccountId) => {
    const [stripeConnectInstance, setStripeConnectInstance] = useState();

    useEffect(() => {
        if (connectedAccountId) {
            const fetchClientSecret = async () => {
                const response = await fetch("https://api.skillupnow.xyz/account_session", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": process.env.REACT_APP_AUTH
                    },
                    body: JSON.stringify({
                        account: connectedAccountId,
                    }),
                });

                if (!response.ok) {
                    // Handle errors on the client side here
                    const { error } = await response.json();
                    throw ("An error occurred: ", error);
                } else {
                    const { client_secret: clientSecret } = await response.json();
                    return clientSecret;
                }
            };

            setStripeConnectInstance(
                loadConnectAndInitialize({
                    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                    fetchClientSecret,
                    appearance: {
                        overlays: "dialog",
                        variables: {
                            colorPrimary: "#52FC1A",
                            colorBackground: "#0E0E0F",
                            buttonPrimaryColorBorder: "#000000",
                            buttonSecondaryColorBackground: "#0E0E0F",
                            buttonSecondaryColorBorder: "#3C3C3C",
                            colorBorder: "#3E3E3E",
                            badgeNeutralColorBackground: "#585858",
                            badgeNeutralColorBorder: "#252525",
                            badgeNeutralColorText: "#C3C3C3",
                        },
                    },
                })
            );
        }
    }, [connectedAccountId]);

    return stripeConnectInstance;
};

export default useStripeConnect;