import React, { useState } from 'react';
import moment from 'moment';
import Rating from '@mui/material/Rating';
import { sendReview } from './functions';

const ReviewPost = ({coach, user}) => {

	const [rate, setRate] = useState(null);
	const [text, setText] = useState("");
	const [posted, setPosted] = useState(false);

	const handleChange = (e, rate) => {
		setRate(rate);
	}

	const postReview = async () => {
		await sendReview(coach, user, rate, text).then(() => {
			setPosted(true);
		})
	}

	if (posted) {
		return (
			<div className='flex flex-col gap-2 items-center'>
				<h1 className='text-6xl'>👌</h1>
				<h2 className='text-2xl font-bold'>Votre avis a été publié !</h2>
			</div>
		)
	}

	return (
		<div id='review_post w-full'>
			<div className='flex flex-col gap-2 w-full'>
				<Rating name="no-value" value={rate} precision={0.5} size='large' onChange={handleChange} />
				<div className='input-container'>
					<input className='m-0' value={text} placeholder='Votre avis' type="text" name="review" id="review" onChange={(e) => {
						setText(e.target.value);
					}} />
				</div>
				{text.trim().length > 5 && rate && <div className='flex justify-start'>
					<div className='button-primary' onClick={postReview}>Envoyer</div>
				</div>}
			</div>
		</div>
	);
};

export default ReviewPost;