import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useAuth } from "./auth";
import { getAPI, postAPI } from "./utils/functions";
import SelectMenu from "./utils/select";
import { enqueueSnackbar } from "notistack";

const DevenirCoach = () => {
	const auth = useAuth();
	const [canAsk, setCanAsk] = useState(true);
	const [niveau, setNiveau] = useState(0);
	const [languages, setLanguages] = useState([]);
	const [plateformes, setPlateformes] = useState([]);
	const [socials, setSocials] = useState({
		discord: "",
		youtube: "",
		twitter: "",
		twitch: "",
	});
	const [jeu, setJeu] = useState(1);
	const [description, setDescription] = useState("");

	const twitchIcon = (
		<svg
			width="30px"
			viewBox="0 0 24.00 24.00"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#ffffff"
			strokeWidth="0.00024000000000000003"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="#CCCCCC"
				strokeWidth="0.192"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.54689 1L1 4.77575V20.2087H6.49952V23H9.5933L12.5153 20.2087H16.9842L23 14.4622V1H2.54689V1ZM20.9368 13.4777L17.4995 16.7612H12L9.07799 19.5524V16.7612H4.43732V2.96992H20.9368V13.4777ZM17.4995 6.74658V12.4868H15.4373V6.74658H17.4995ZM12 6.74658V12.4868H9.9378V6.74658H12Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const discordIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M18.8944 4.34399C17.5184 3.71467 16.057 3.256 14.5317 3C14.3397 3.33067 14.1263 3.77866 13.977 4.13067C12.3546 3.89599 10.7439 3.89599 9.14394 4.13067C8.9946 3.77866 8.77059 3.33067 8.58925 3C7.05328 3.256 5.59194 3.71467 4.22555 4.34399C1.46289 8.41865 0.716219 12.3973 1.08955 16.3226C2.92421 17.6559 4.6949 18.4666 6.43463 19C6.86129 18.424 7.2453 17.8053 7.57597 17.1546C6.94663 16.92 6.3493 16.632 5.7733 16.2906C5.92263 16.184 6.07197 16.0667 6.21064 15.9493C9.68796 17.5387 13.4544 17.5387 16.889 15.9493C17.0383 16.0667 17.177 16.184 17.3263 16.2906C16.7503 16.632 16.153 16.92 15.5237 17.1546C15.8543 17.8053 16.2384 18.424 16.665 19C18.4037 18.4666 20.185 17.6559 22.0101 16.3226C22.4687 11.7787 21.2837 7.83202 18.8944 4.34399ZM8.05596 13.9013C7.01061 13.9013 6.15728 12.952 6.15728 11.7893C6.15728 10.6267 6.98928 9.67731 8.05596 9.67731C9.11194 9.67731 9.97591 10.6267 9.95457 11.7893C9.95457 12.952 9.11194 13.9013 8.05596 13.9013ZM15.065 13.9013C14.0197 13.9013 13.1653 12.952 13.1653 11.7893C13.1653 10.6267 13.9983 9.67731 15.065 9.67731C16.121 9.67731 16.985 10.6267 16.9637 11.7893C16.9637 12.952 16.1317 13.9013 15.065 13.9013Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const youtubeIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.5949 4.45999C21.5421 4.71353 22.2865 5.45785 22.54 6.40501C22.9982 8.12002 23 11.7004 23 11.7004C23 11.7004 23 15.2807 22.54 16.9957C22.2865 17.9429 21.5421 18.6872 20.5949 18.9407C18.88 19.4007 12 19.4007 12 19.4007C12 19.4007 5.12002 19.4007 3.405 18.9407C2.45785 18.6872 1.71353 17.9429 1.45999 16.9957C1 15.2807 1 11.7004 1 11.7004C1 11.7004 1 8.12002 1.45999 6.40501C1.71353 5.45785 2.45785 4.71353 3.405 4.45999C5.12002 4 12 4 12 4C12 4 18.88 4 20.5949 4.45999ZM15.5134 11.7007L9.79788 15.0003V8.40101L15.5134 11.7007Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const twitterIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M22 5.9246C21.2645 6.25051 20.4744 6.47071 19.6438 6.57025C20.4911 6.06289 21.1412 5.25782 21.4477 4.29948C20.655 4.76984 19.7768 5.1116 18.8422 5.29481C18.0935 4.49855 17.0277 4 15.8474 4C13.582 4 11.7446 5.8374 11.7446 8.10464C11.7446 8.42526 11.7816 8.73707 11.8503 9.03832C8.43883 8.86656 5.41672 7.23263 3.39258 4.75046C3.04025 5.35823 2.83766 6.06289 2.83766 6.81335C2.83766 8.23677 3.56258 9.4937 4.66273 10.2292C3.98978 10.2072 3.35735 10.0231 2.80419 9.71567V9.76852C2.80419 11.7565 4.21792 13.4151 6.09583 13.7921C5.75055 13.8855 5.38853 13.9348 5.01506 13.9348C4.75081 13.9348 4.49273 13.9102 4.24258 13.8626C4.76491 15.4921 6.27993 16.6795 8.07593 16.7112C6.67101 17.8122 4.90144 18.4684 2.97948 18.4684C2.64829 18.4684 2.3215 18.449 2 18.4112C3.81626 19.5765 5.97252 20.2547 8.28909 20.2547C15.8378 20.2547 19.9644 14.0026 19.9644 8.58029C19.9644 8.40412 19.96 8.2262 19.9521 8.05003C20.7536 7.47045 21.4495 6.74905 21.9982 5.92724L22 5.9246Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);

	useLayoutEffect(() => {
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);
		document.getElementById("devenir-coach").classList.add("active-link");
	});

	const Editor = () => {
		return (
			<ReactQuill
				theme="snow"
				value={description}
				onChange={(value) => {
					setDescription((prev) => {
						return value;
					});
				}}
			/>
		);
	};

	const requestForm = async () => {
		let languages_ok = languages.length >= 1;
		let plateformes_ok = plateformes.length >= 1;
		let jeu_ok = jeu.length !== "";

		if (languages_ok && plateformes_ok && jeu_ok) {
			// SUBMIT
			await postAPI(`/user/devenirCoach`, {
				id: auth.user.id,
				...socials,
				jeux: jeu,
				description: description,
				language: languages.join(","),
				plateforme: plateformes.join(","),
				niveau: niveau,
			}).then((data) => {
				if (data.affectedRows > 0) {
					enqueueSnackbar(`Votre demande a été envoyée!`, {
						variant: "success",
					});
					setCanAsk(false);
				}
			});
		} else {
			enqueueSnackbar(
				`Certaines des informations demandées ne sont pas complètes`,
				{ variant: "error" },
			);
		}
	};

	useEffect(() => {
		getAPI(`/user/getDemandeCoach`).then((result) => {
			if (result.length > 0) {
				setCanAsk(false);
			}
		});
	});

	return (
		<div className="p-4 px-[150px] flex flex-col gap-14 leading-5">
			{/* <div className='header'>
				<div className='text-4xl font-bold'>
					Devenir <span className='text-primary'>coach</span>
				</div>
				<div className='text-2xl opacity-75'>
					Transmettez vos connaissances!
				</div>
			</div> */}

			<div className="body">
				<div className="text-2xl font-bold font-sf-pro-e">
					Vous voulez devenir{" "}
					<span className="text-primary">coach</span> ?
				</div>
				<div className="opacity-75">
					Présentez ce formulaire. Une réponse vous sera rendue une
					fois votre demande analysée par nos coachs et
					administrateurs. Un minimum de rédaction est attendu.
					Soignez votre écriture pour maximiser vos chances !
				</div>
			</div>
			{canAsk ? (
				auth.user.coach === "0" ? (
					<div className="form flex justify-center gap-14 w-full">
						<div className="left w-full pb-5 flex flex-col gap-8">
							{/* JEU */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Jeu
									</h3>
									<p className="text-white/75">
										Choisissez le jeu sur lequel vous
										souhaitez devenir coach. Notez que cette
										information n'est pas modifiable !
									</p>
								</div>
								<SelectMenu
									value={jeu}
									defaultValue={1}
									onChange={(e) => {
										setJeu(e.target.value);
									}}
									elements={[
										{ value: 1, text: "Rocket League" },
										{ value: 2, text: "Valorant" },
									]}
								></SelectMenu>
							</div>

							{/* NIVEAU */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Niveau de coaching
									</h3>
									<p className="text-white/75">
										Choisissez votre niveau actuel de
										coaching.
									</p>
								</div>
								<SelectMenu
									value={niveau}
									defaultValue={niveau}
									onChange={(e) => {
										setNiveau(e.target.value);
									}}
									elements={[
										{ value: 0, text: "Débutant" },
										{
											value: 1,
											text: "Débutant à Intermédiaire",
										},
										{ value: 2, text: "Débutant à Avancé" },
										{ value: 3, text: "Débutant à Expert" },
									]}
								></SelectMenu>
							</div>

							{/* LANGUES */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Vos langues
									</h3>
									<p className="text-white/75">
										Ajoutez vos langues pour élargir votre
										publique
									</p>
								</div>
								<div className="flex items-center gap-3">
									<div
										id="english_l"
										className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
										onClick={(e) => {
											let th = document.getElementById(
												e.currentTarget.id,
											);
											th.classList.toggle(
												"border-primary",
											);
											th.classList.toggle(
												"border-white/10",
											);
											setLanguages((prev) => {
												if (
													prev.indexOf(
														e.target.id.slice(
															0,
															-2,
														),
													) === -1
												) {
													let t = prev;
													t.push(
														e.target.id.slice(
															0,
															-2,
														),
													);
													return t;
												} else {
													let t = prev;
													t.splice(
														prev.indexOf(
															e.target.id.slice(
																0,
																-2,
															),
														),
														1,
													);
													return t;
												}
											});
										}}
									>
										English
									</div>
									<div
										id="france_l"
										className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
										onClick={(e) => {
											let th = document.getElementById(
												e.target.id,
											);
											th.classList.toggle(
												"border-primary",
											);
											th.classList.toggle(
												"border-white/10",
											);
											setLanguages((prev) => {
												if (
													prev.indexOf(
														e.target.id.slice(
															0,
															-2,
														),
													) === -1
												) {
													let t = prev;
													t.push(
														e.target.id.slice(
															0,
															-2,
														),
													);
													return t;
												} else {
													let t = prev;
													t.splice(
														prev.indexOf(
															e.target.id.slice(
																0,
																-2,
															),
														),
														1,
													);
													return t;
												}
											});
										}}
									>
										Français
									</div>
								</div>
							</div>

							{/* PLATEFORMES */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Vos plateformes
									</h3>
									<p className="text-white/75">
										Renseignez les plateformes sur
										lesquelles vous acceptez de coacher
									</p>
								</div>
								<div className="flex items-center gap-3">
									{" "}
									{/* PLATEFORMES */}
									<div
										id="PC"
										className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
										onClick={(e) => {
											let th = document.getElementById(
												e.currentTarget.id,
											);
											th.classList.toggle(
												"border-primary",
											);
											th.classList.toggle(
												"border-white/10",
											);
											setPlateformes((prev) => {
												if (
													prev.indexOf(
														e.target.id,
													) === -1
												) {
													let t = prev;
													t.push(e.target.id);
													return t;
												} else {
													let t = prev;
													t.splice(
														prev.indexOf(
															e.target.id,
														),
														1,
													);
													return t;
												}
											});
										}}
									>
										PC
									</div>
									<div
										id="PLAYSTATION"
										className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
										onClick={(e) => {
											let th = document.getElementById(
												e.target.id,
											);
											th.classList.toggle(
												"border-primary",
											);
											th.classList.toggle(
												"border-white/10",
											);
											setPlateformes((prev) => {
												if (
													prev.indexOf(
														e.target.id,
													) === -1
												) {
													let t = prev;
													t.push(e.target.id);
													return t;
												} else {
													let t = prev;
													t.splice(
														prev.indexOf(
															e.target.id,
														),
														1,
													);
													return t;
												}
											});
										}}
									>
										PS4 / PS5
									</div>
									<div
										id="XBOX"
										className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
										onClick={(e) => {
											let th = document.getElementById(
												e.target.id,
											);
											th.classList.toggle(
												"border-primary",
											);
											th.classList.toggle(
												"border-white/10",
											);
											setPlateformes((prev) => {
												if (
													prev.indexOf(
														e.target.id,
													) === -1
												) {
													let t = prev;
													t.push(e.target.id);
													return t;
												} else {
													let t = prev;
													t.splice(
														prev.indexOf(
															e.target.id,
														),
														1,
													);
													return t;
												}
											});
										}}
									>
										XBOX
									</div>
								</div>
							</div>

							{/* RESEAUX */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Vos réseaux
									</h3>
									<p className="text-white/75">
										Ajoutez vos réseaux à votre profil
										(https://example.com/...)
									</p>
								</div>
								<div className="flex flex-col gap-2 items-stretch">
									{/* RESEAUX */}
									<div
										className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
										id="discord"
									>
										{discordIcon}
										<input
											type="text"
											value={socials.discord || ""}
											onChange={(e) => {
												setSocials((prev) => {
													return {
														...prev,
														discord: e.target.value,
													};
												});
											}}
										/>
									</div>
									<div
										className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
										id="youtube"
									>
										{youtubeIcon}
										<input
											type="text"
											value={socials.youtube || ""}
											onChange={(e) => {
												setSocials((prev) => {
													return {
														...prev,
														youtube: e.target.value,
													};
												});
											}}
										/>
									</div>
									<div
										className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
										id="twitter"
									>
										{twitterIcon}
										<input
											type="text"
											value={socials.twitter || ""}
											onChange={(e) => {
												setSocials((prev) => {
													return {
														...prev,
														twitter: e.target.value,
													};
												});
											}}
										/>
									</div>
									<div
										className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
										id="twitch"
									>
										{twitchIcon}
										<input
											type="text"
											value={socials.twitch || ""}
											onChange={(e) => {
												setSocials((prev) => {
													return {
														...prev,
														twitch: e.target.value,
													};
												});
											}}
										/>
									</div>
								</div>
							</div>

							{/* DESCRIPTION */}
							<div className="flex flex-col gap-4">
								<div className="leading-[20px]">
									<h3 className="font-bold font-sf-pro-e text-base">
										Description
									</h3>
									<p className="text-white/75">
										Ajoutez une courte description de vous.
										(10 caractères minimum)
									</p>
								</div>
								<Editor />
							</div>

							<div className="w-full flex justify-end">
								<div
									className="button-primary max-w-40"
									onClick={requestForm}
								>
									Envoyer
								</div>
							</div>
						</div>

						<div className="separator border-l-2 border-white/25" />

						<div className="right w-full pb-5"></div>
					</div>
				) : (
					<div className="left w-full pb-5 flex flex-col gap-8">
						<h2 className="text-2xl text-center opacity-95 font-bold">
							Vous êtes déjà coach, que voulez-vous de plus ?
							Devenir certifié ? 😁
						</h2>
					</div>
				)
			) : (
				<div className="left w-full pb-5 flex flex-col gap-8">
					<h2 className="text-2xl text-center opacity-95 font-bold">
						Votre demande est en cours de traitement, un peu de
						patience 😁
					</h2>
				</div>
			)}
		</div>
	);
};

export default DevenirCoach;
