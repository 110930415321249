import React, { useState, useEffect } from "react";
import { useAuth } from "../auth";
import VerifiedIcon from "@mui/icons-material/Verified";
import Avatar from "./avatar";

const ValidationSeance = ({ user, context }) => {
	const auth = useAuth();

	return (
		<span className="flex flex-col gap-1 h-full w-full">
			<Avatar image={user.image} size={200} radius={"rounded-md"} />
			<div className="flex items-center gap-2">
				<h2 className="text-xl font-bold font-sf-pro-e whitespace-nowrap">
					{user.pseudo + (auth.user.id == user.id ? " (Vous)" : "")}
				</h2>
				{user.verified && <VerifiedIcon />}
			</div>
			{context[user.id] ? (
				<div className="flex items-center gap-2">
					<div className="w-3 h-3 rounded-full bg-primary"></div>
					<h3 className="font-sf-pro-e font-bold">Prêt</h3>
				</div>
			) : (
				<div>
					<div className="flex items-center gap-2">
						<div className="w-3 h-3 rounded-full bg-[#5E98FC]"></div>
						<h3 className="font-sf-pro-e font-bold">En attente</h3>
					</div>
				</div>
			)}
		</span>
	);
};

export default ValidationSeance;
