import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SelectMenu = ({ value, defaultValue, onChange, elements }) => {
	return (
		<Select value={value}
			label="Niveau"
			size='small'
			notched={false}
			disableUnderline={true}
			variant='standard'
			defaultValue={defaultValue}
			sx={{
				backgroundColor: '#1B1B1B',
				color: 'white',
				border: '2px solid rgba(255, 255, 255, 0.10)',
				maxWidth: '250px',
				outline: 'none !important',
				boxShadow: 'none',
				borderRadius: '4px',
				padding: '6px 10px 0px 10px',
				alignItems: 'center'
			}}
			onChange={onChange}
		>
			{
				Object.values(elements).map((item) => {
					return <MenuItem sx={{ backgroundColor: '#0E0E0F', color: 'white', '&:hover': { backgroundColor: '#1B1B1B', color: 'white' } }} value={item.value}>{item.text}</MenuItem>
				})
			}
		</Select>
	);
}

export default SelectMenu;
