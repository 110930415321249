const PulsingDot = () => {
	return (
		<div className='pusling-dot'>
			<div className="ring-container relative">
				<div className="ringring"></div>
				<div className="circle"></div>
			</div>
		</div>
	);
}

export default PulsingDot;