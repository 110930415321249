import "./App.css";

import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { Navbar } from "./components/navbar";
import Home from "./components/home";
import { NotFound } from "./components/404";
import { Login } from "./components/login";
import { Register } from "./components/register";
import ForgotPassword from "./components/forgotpassword";
import Coach from "./components/coach";
import Coachs from "./components/coachs";
import { DiscordAuth } from "./components/authDiscord";
import { AuthProvider } from "./components/auth";
import { Profile } from "./components/profile";
import RequireAuth from "./components/requireAuth";
import LinkDiscord from "./components/linkDiscord";
import Checkout from "./components/checkout";
import ConfirmCheckout from "./components/confirmCheckout";
import Reserve from "./components/reserve";
import { LoginDiscord } from "./components/loginDiscord";
import SeanceLoader from "./components/seanceLoader";
import DevenirCoach from "./components/devenirCoach";
import ResetPassword from "./components/utils/resetPassword";
import ChatContainer from "./components/chatContainer";
import { io } from "socket.io-client";
import HashManager from "./components/hashManager";

const App = () => {
	const [socket, setSocket] = useState(null);

	useEffect(() => {
		setSocket(
			io(`${process.env.REACT_APP_API_URI}`, {
				transports: ["websocket", "polling"],
			}),
		);

		return () => {
			socket.disconnect();
			setSocket(null);
		};
	}, []);

	return (
		// RENDER APP
		<div className="app">
			<AuthProvider>
				<SnackbarProvider>
					<BrowserRouter>
						<Navbar />
						<SeanceLoader socket={socket} />
						<HashManager />
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/home" element={<Home />} />
							<Route path="/login" element={<Login />} />
							<Route path="/signup" element={<Register />} />
							<Route
								path="/signup/discord"
								element={<DiscordAuth />}
							/>
							<Route
								path="/login/discord"
								element={<LoginDiscord />}
							/>
							<Route
								path="/forgotpassword"
								element={<ForgotPassword />}
							/>
							<Route
								path="/reset/:arg"
								element={<ResetPassword />}
							/>
							<Route path="/coachs" element={<Coachs />} />
							<Route
								path="/coachs/:coachParam"
								element={<Coach />}
							/>

							<Route
								path="/devenir-coach"
								element={
									<RequireAuth>
										<DevenirCoach />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/profile"
								element={
									<RequireAuth>
										<Profile />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/chat"
								element={
									<RequireAuth>
										<ChatContainer socket={socket} />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/link/discord"
								element={
									<RequireAuth>
										<LinkDiscord />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/checkout/:formule"
								element={
									<RequireAuth>
										<Checkout />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/confirm-order/:facture"
								element={
									<RequireAuth>
										{" "}
										<ConfirmCheckout />{" "}
									</RequireAuth>
								}
							/>
							<Route
								path="/session/reserve/:facture"
								element={
									<RequireAuth>
										{" "}
										<Reserve />{" "}
									</RequireAuth>
								}
							/>

							<Route path="*" element={<NotFound />} />
						</Routes>
					</BrowserRouter>
				</SnackbarProvider>
			</AuthProvider>
		</div>
	);
};

export default App;
