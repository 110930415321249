import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";

import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";

import { useAuth } from "../auth";
import { getAPI, postAPI } from "../utils/functions";

import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import SelectMenu from "../utils/select";
import Editor from "../utils/editor";

export const Dashboard = () => {
	const [user, setUser] = useState(null);
	const [description, setDescription] = useState("");
	const [languages, setLanguages] = useState([]);
	const [plateformes, setPlateformes] = useState([]);
	const [socials, setSocials] = useState({});
	const [eventsDispo, setEventsDispo] = useState([]);
	const [niveau, setNiveau] = useState(0);
	const [formules, setFormules] = useState([]);
	const [bestPrice, setBestPrice] = useState(0);

	const [hasChanged, setHasChanged] = useState(false);
	const [loadingChanges, setLoadingChanges] = useState(false);
	const [loading, setLoading] = useState(true);

	const auth = useAuth();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const twitchIcon = (
		<svg
			width="30px"
			viewBox="0 0 24.00 24.00"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#ffffff"
			strokeWidth="0.00024000000000000003"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="#CCCCCC"
				strokeWidth="0.192"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.54689 1L1 4.77575V20.2087H6.49952V23H9.5933L12.5153 20.2087H16.9842L23 14.4622V1H2.54689V1ZM20.9368 13.4777L17.4995 16.7612H12L9.07799 19.5524V16.7612H4.43732V2.96992H20.9368V13.4777ZM17.4995 6.74658V12.4868H15.4373V6.74658H17.4995ZM12 6.74658V12.4868H9.9378V6.74658H12Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const discordIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M18.8944 4.34399C17.5184 3.71467 16.057 3.256 14.5317 3C14.3397 3.33067 14.1263 3.77866 13.977 4.13067C12.3546 3.89599 10.7439 3.89599 9.14394 4.13067C8.9946 3.77866 8.77059 3.33067 8.58925 3C7.05328 3.256 5.59194 3.71467 4.22555 4.34399C1.46289 8.41865 0.716219 12.3973 1.08955 16.3226C2.92421 17.6559 4.6949 18.4666 6.43463 19C6.86129 18.424 7.2453 17.8053 7.57597 17.1546C6.94663 16.92 6.3493 16.632 5.7733 16.2906C5.92263 16.184 6.07197 16.0667 6.21064 15.9493C9.68796 17.5387 13.4544 17.5387 16.889 15.9493C17.0383 16.0667 17.177 16.184 17.3263 16.2906C16.7503 16.632 16.153 16.92 15.5237 17.1546C15.8543 17.8053 16.2384 18.424 16.665 19C18.4037 18.4666 20.185 17.6559 22.0101 16.3226C22.4687 11.7787 21.2837 7.83202 18.8944 4.34399ZM8.05596 13.9013C7.01061 13.9013 6.15728 12.952 6.15728 11.7893C6.15728 10.6267 6.98928 9.67731 8.05596 9.67731C9.11194 9.67731 9.97591 10.6267 9.95457 11.7893C9.95457 12.952 9.11194 13.9013 8.05596 13.9013ZM15.065 13.9013C14.0197 13.9013 13.1653 12.952 13.1653 11.7893C13.1653 10.6267 13.9983 9.67731 15.065 9.67731C16.121 9.67731 16.985 10.6267 16.9637 11.7893C16.9637 12.952 16.1317 13.9013 15.065 13.9013Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const youtubeIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.5949 4.45999C21.5421 4.71353 22.2865 5.45785 22.54 6.40501C22.9982 8.12002 23 11.7004 23 11.7004C23 11.7004 23 15.2807 22.54 16.9957C22.2865 17.9429 21.5421 18.6872 20.5949 18.9407C18.88 19.4007 12 19.4007 12 19.4007C12 19.4007 5.12002 19.4007 3.405 18.9407C2.45785 18.6872 1.71353 17.9429 1.45999 16.9957C1 15.2807 1 11.7004 1 11.7004C1 11.7004 1 8.12002 1.45999 6.40501C1.71353 5.45785 2.45785 4.71353 3.405 4.45999C5.12002 4 12 4 12 4C12 4 18.88 4 20.5949 4.45999ZM15.5134 11.7007L9.79788 15.0003V8.40101L15.5134 11.7007Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const twitterIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M22 5.9246C21.2645 6.25051 20.4744 6.47071 19.6438 6.57025C20.4911 6.06289 21.1412 5.25782 21.4477 4.29948C20.655 4.76984 19.7768 5.1116 18.8422 5.29481C18.0935 4.49855 17.0277 4 15.8474 4C13.582 4 11.7446 5.8374 11.7446 8.10464C11.7446 8.42526 11.7816 8.73707 11.8503 9.03832C8.43883 8.86656 5.41672 7.23263 3.39258 4.75046C3.04025 5.35823 2.83766 6.06289 2.83766 6.81335C2.83766 8.23677 3.56258 9.4937 4.66273 10.2292C3.98978 10.2072 3.35735 10.0231 2.80419 9.71567V9.76852C2.80419 11.7565 4.21792 13.4151 6.09583 13.7921C5.75055 13.8855 5.38853 13.9348 5.01506 13.9348C4.75081 13.9348 4.49273 13.9102 4.24258 13.8626C4.76491 15.4921 6.27993 16.6795 8.07593 16.7112C6.67101 17.8122 4.90144 18.4684 2.97948 18.4684C2.64829 18.4684 2.3215 18.449 2 18.4112C3.81626 19.5765 5.97252 20.2547 8.28909 20.2547C15.8378 20.2547 19.9644 14.0026 19.9644 8.58029C19.9644 8.40412 19.96 8.2262 19.9521 8.05003C20.7536 7.47045 21.4495 6.74905 21.9982 5.92724L22 5.9246Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);

	useEffect(() => {
		getAPI(`/coachs/${auth.user.pseudo}`).then((dat) => {
			setUser(dat);
			setDescription(dat.description);
			setLanguages(
				Array.from(dat.language.split(",")).map((l) => l.trim()),
			);
			setPlateformes(
				Array.from(dat.plateforme.split(",")).map((l) => l.trim()),
			);
			setNiveau(dat.niveau);
			let soc = {
				discord: dat.discord,
				youtube: dat.youtube,
				twitter: dat.twitter,
				twitch: dat.twitch,
			};
			setSocials(soc);
			// Load events dispo
			if (!dat.dispo) {
				let arr = [];
				for (let i = 0; i < 168; i++) {
					arr.push({
						id: i,
						title: ``,
						start: moment()
							.startOf("isoWeek")
							.add(i, "hour")
							.format(),
						end: moment()
							.startOf("week")
							.add(i + 1, "hour")
							.format(),
						allDay: false,
						color: "#ffa2b1",
						display: "background",
						dispo: false,
					});
				}
				setEventsDispo(arr);
			} else {
				let arr = [];
				for (let i = 0; i < 168; i++) {
					arr.push({
						id: i,
						title: ``,
						start: moment()
							.startOf("isoWeek")
							.add(i, "hour")
							.format(),
						end: moment()
							.startOf("week")
							.add(i + 1, "hour")
							.format(),
						allDay: false,
						color:
							JSON.parse(dat.dispo)[i] === false
								? "#ffa2b1"
								: "rgb(0, 255, 0)",
						display: "background",
						dispo: JSON.parse(dat.dispo)[i],
					});
				}
				setEventsDispo(arr);
			}

			setHasChanged(false);
		});

		getAPI(`/formules/${auth.user.id}`).then((data) => {
			setFormules(data);
		});

		setLoading(false);
	}, []);

	const saveChanges = async () => {
		if (!isFormulesOk()) {
			enqueueSnackbar(
				"Des informations sont manquantes sur une ou plusieurs formule(s)",
				{ variant: "error" },
			);
			setHasChanged(false);
			return;
		}
		setLoadingChanges(true);
		let newUser = {
			description: description,
			...socials,
			discord: socials.discord,
			youtube: socials.youtube,
			twitter: socials.twitter,
			twitch: socials.twitch,
			language: languages.join(","),
			plateforme: plateformes.join(","),
			niveau: niveau,
			meilleur_prix: bestPrice,
			dispo: JSON.stringify(eventsDispo),
		};

		await postAPI(`/user/updateCoach`, {
			...newUser,
		}).then((data) => {
			if (data.affectedRows > 0) {
				enqueueSnackbar("Changements sauvegardés.", {
					variant: "success",
					autoHideDuration: 2000,
				});
				setHasChanged(false);
			} else {
				enqueueSnackbar("Une erreur s'est produite.", {
					variant: "error",
				});
				setHasChanged(false);
			}
		});

		for (const formule of formules) {
			if (!formule.id_formule) {
				await postAPI(`/user/createFormule`, {
					...formule,
				});
			} else {
				await postAPI(`/user/updateFormule`, {
					...formule,
				});
			}
		}
		setLoadingChanges(false);
	};

	useLayoutEffect(() => {
		/* LOAD INFORMATIONS */
		if (user) {
			// Load languages
			let langs = Array.from(user.language.split(",")).map((l) =>
				l.trim(),
			);
			langs.forEach((l) => {
				let lang_el = document.getElementById(`${l}_l`);
				if (!lang_el) return;
				lang_el.classList.add("border-primary");
				lang_el.classList.remove("border-white/10");
			});

			// Load plateformes
			let plats = Array.from(user.plateforme.split(",")).map((l) =>
				l.trim(),
			);
			plats.forEach((l) => {
				let plat_el = document.getElementById(`${l}`);
				if (!plat_el) return;
				plat_el.classList.add("border-primary");
				plat_el.classList.remove("border-white/10");
			});
		}
	}, [user]);

	useLayoutEffect(() => {
		/* CALENDAR */
		if (!user) return;
		let calendar = new Calendar(document.getElementById("calendar"), {
			plugins: [timeGridPlugin],
			initialView: "timeGridWeek",
			slotDuration: "01:00",
			firstDay: "1",
			allDaySlot: false,
			nowIndicator: true,
			eventShortHeight: 15,
			headerToolbar: {
				left: "title",
				center: "",
				right: "",
			},
			buttonText: {
				today: "Aujourd'hui",
				month: "Mois",
				week: "Semaine",
				day: "Jour",
				list: "Liste",
			},
			eventMouseEnter: () => {
				document.body.style.cursor = "pointer";
			},
			eventMouseLeave: () => {
				document.body.style.cursor = "auto";
			},
			eventClick: (info) => {
				if (info.el.style.backgroundColor === "rgb(0, 255, 0)") {
					info.el.style.backgroundColor = "rgb(255, 162, 177)";
					info.event.setExtendedProp("dispo", false);
				} else if (
					info.el.style.backgroundColor === "rgb(255, 162, 177)"
				) {
					info.el.style.backgroundColor = "rgb(0, 255, 0)";
					info.event.setExtendedProp("dispo", true);
				}
				setHasChanged(true);
				setEventsDispo(
					calendar
						.getEvents()
						.map((e) => e["_def"].extendedProps.dispo),
				);
			},
			eventSources: [eventsDispo],
		});
		calendar.render();
		setEventsDispo(
			calendar.getEvents().map((e) => e["_def"].extendedProps.dispo),
		);
	}, [user]);

	useEffect(() => {
		/* BEST PRICE */
		if (formules) {
			// Compute best price
			let prices = formules.map((f) => f.prix);
			prices.sort();
			setBestPrice(prices[0]);
		}
	}, [formules]);

	const isFormulesOk = () => {
		let ret = true;
		formules.forEach((formule, index) => {
			if (
				formule.titre === "" ||
				formule.description_f === "" ||
				formule.heures_f === "" ||
				formule.prix === ""
			) {
				ret = false;
			}
		});
		return ret;
	};

	if (loading) {
		return (
			<div className="flex flex-col gap-10 w-full">
				<div className="flex flex-col gap-4">
					<div className="leading-[20px]">
						<h3 className="font-bold font-sf-pro-e text-2xl">
							Tableau de bord coach
						</h3>
						<p className="text-white/75">
							Vous pouvez accéder ici à tous les paramètres
							concernant votre profil de coach
						</p>
					</div>
				</div>

				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-10 w-full">
			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Tableau de bord coach
					</h3>
					<p className="text-white/75">
						Vous pouvez accéder ici à tous les paramètres concernant
						votre profil de coach
					</p>
				</div>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Description
					</h3>
					<p className="text-white/75">
						Ajoutez une description pour rendre votre profil plus
						attrayant et personnalisé
					</p>
				</div>
				<Editor
					content={description}
					onChange={(e) => {
						setDescription(e.target.value);
						setHasChanged(true);
					}}
				/>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Vos réseaux
					</h3>
					<p className="text-white/75">
						Ajoutez vos réseaux à votre profil pour gagner en
						visibilité
					</p>
				</div>
				<div className="flex flex-col gap-2 items-stretch">
					{" "}
					{/* RÉSEAUX */}
					<div
						className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
						id="discord"
					>
						{discordIcon}
						<input
							type="text"
							value={socials.discord || ""}
							onChange={(e) => {
								setSocials((prev) => {
									return { ...prev, discord: e.target.value };
								});
								setHasChanged(true);
							}}
						/>
					</div>
					<div
						className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
						id="youtube"
					>
						{youtubeIcon}
						<input
							type="text"
							value={socials.youtube || ""}
							onChange={(e) => {
								setSocials((prev) => {
									return { ...prev, youtube: e.target.value };
								});
								setHasChanged(true);
							}}
						/>
					</div>
					<div
						className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
						id="twitter"
					>
						{twitterIcon}
						<input
							type="text"
							value={socials.twitter || ""}
							onChange={(e) => {
								setSocials((prev) => {
									return { ...prev, twitter: e.target.value };
								});
								setHasChanged(true);
							}}
						/>
					</div>
					<div
						className="flex items-center gap-3 p-2 rounded bg-[#1B1B1B] border-2 border-white/10"
						id="twitch"
					>
						{twitchIcon}
						<input
							type="text"
							value={socials.twitch || ""}
							onChange={(e) => {
								setSocials((prev) => {
									return { ...prev, twitch: e.target.value };
								});
								setHasChanged(true);
							}}
						/>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Vos langues
					</h3>
					<p className="text-white/75">
						Ajoutez vos langues pour élargir votre publique
					</p>
				</div>
				<div className="flex items-center gap-3">
					{" "}
					{/* LANGUES */}
					<div
						id="english_l"
						className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
						onClick={(e) => {
							let th = document.getElementById(
								e.currentTarget.id,
							);
							th.classList.toggle("border-primary");
							th.classList.toggle("border-white/10");
							setLanguages((prev) => {
								if (
									prev.indexOf(e.target.id.slice(0, -2)) ===
									-1
								) {
									let t = prev;
									t.push(e.target.id.slice(0, -2));
									return t;
								} else {
									let t = prev;
									t.splice(
										prev.indexOf(e.target.id.slice(0, -2)),
										1,
									);
									return t;
								}
							});
							setHasChanged(true);
						}}
					>
						English
					</div>
					<div
						id="france_l"
						className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
						onClick={(e) => {
							let th = document.getElementById(e.target.id);
							th.classList.toggle("border-primary");
							th.classList.toggle("border-white/10");
							setLanguages((prev) => {
								if (
									prev.indexOf(e.target.id.slice(0, -2)) ===
									-1
								) {
									let t = prev;
									t.push(e.target.id.slice(0, -2));
									return t;
								} else {
									let t = prev;
									t.splice(
										prev.indexOf(e.target.id.slice(0, -2)),
										1,
									);
									return t;
								}
							});
							setHasChanged(true);
						}}
					>
						Français
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Vos plateformes
					</h3>
					<p className="text-white/75">
						Renseignez les plateformes sur lesquelles vous acceptez
						de coacher
					</p>
				</div>
				<div className="flex items-center gap-3">
					{" "}
					{/* PLATEFORMES */}
					<div
						id="PC"
						className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
						onClick={(e) => {
							let th = document.getElementById(
								e.currentTarget.id,
							);
							th.classList.toggle("border-primary");
							th.classList.toggle("border-white/10");
							setPlateformes((prev) => {
								if (prev.indexOf(e.target.id) === -1) {
									let t = prev;
									t.push(e.target.id);
									return t;
								} else {
									let t = prev;
									t.splice(prev.indexOf(e.target.id), 1);
									return t;
								}
							});
							setHasChanged(true);
						}}
					>
						PC
					</div>
					<div
						id="PLAYSTATION"
						className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
						onClick={(e) => {
							let th = document.getElementById(e.target.id);
							th.classList.toggle("border-primary");
							th.classList.toggle("border-white/10");
							setPlateformes((prev) => {
								if (prev.indexOf(e.target.id) === -1) {
									let t = prev;
									t.push(e.target.id);
									return t;
								} else {
									let t = prev;
									t.splice(prev.indexOf(e.target.id), 1);
									return t;
								}
							});
							setHasChanged(true);
						}}
					>
						PS4 / PS5
					</div>
					<div
						id="XBOX"
						className="rounded flex items-center gap-1 p-2 px-3 border-2 border-white/10 cursor-pointer"
						onClick={(e) => {
							let th = document.getElementById(e.target.id);
							th.classList.toggle("border-primary");
							th.classList.toggle("border-white/10");
							setPlateformes((prev) => {
								if (prev.indexOf(e.target.id) === -1) {
									let t = prev;
									t.push(e.target.id);
									return t;
								} else {
									let t = prev;
									t.splice(prev.indexOf(e.target.id), 1);
									return t;
								}
							});
							setHasChanged(true);
						}}
					>
						XBOX
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Vos disponibilités
					</h3>
					<p className="text-white/75">
						Vos disponibilités permettent aux élèves de réserver les
						séances sur les heures que vous avez au préalablement
						définies
					</p>
				</div>
				<div id="calendar" className="calendar calendar-week"></div>
			</div>

			<div className="flex flex-col gap-4">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Niveau de coaching
					</h3>
					<p className="text-white/75">
						Choisissez votre niveau actuel de coaching. En
						choisissant "Débutant à Expert", votre profil est
						susceptible d'être vérifié par nos experts.
					</p>
				</div>
				{/* NIVEAU */}
				<SelectMenu
					value={niveau}
					defaultValue={niveau}
					onChange={(e) => {
						setNiveau(e.target.value);
						setHasChanged(true);
					}}
					elements={[
						{ value: 0, text: "Débutant" },
						{ value: 1, text: "Débutant à Intermédiaire" },
						{ value: 2, text: "Débutant à Avancé" },
						{ value: 3, text: "Débutant à Expert" },
					]}
				></SelectMenu>
			</div>

			<div className="flex flex-col gap-4 w-fit">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-base">
						Vos formules
					</h3>
					<p className="text-white/75">
						Ajoutez ou supprimez des formules à votre profil pour
						permettre à vos élèves de choisir la formule qui
						convient le plus à leur besoin.
					</p>
				</div>
				<div className="flex flex-col gap-4">
					{" "}
					{/* FORMULES */}
					{formules.map((formule, index) => {
						return (
							<div
								key={index}
								className="bg-[#1B1B1B] rounded border-2 border-white/10 p-3 flex flex-col gap-4"
							>
								<div className="flex gap-4">
									<div>
										<h4>Titre</h4>
										<div className={`input-container`}>
											<input
												type="text"
												name="titre"
												id="titre"
												value={formule.titre}
												onChange={(e) => {
													setFormules((prev) => {
														let newT = prev;
														newT[index].titre =
															e.target.value;
														return newT.map(
															(v) => v,
														);
													});
													setHasChanged(true);
												}}
											/>
										</div>
									</div>

									<div>
										<h4>Description</h4>
										<div className={`input-container`}>
											<input
												type="text"
												name="description"
												id="description"
												value={formule.description_f}
												onChange={(e) => {
													setFormules((prev) => {
														let newT = prev;
														newT[
															index
														].description_f =
															e.target.value;
														return newT.map(
															(v) => v,
														);
													});
													setHasChanged(true);
												}}
											/>
										</div>
									</div>

									<div>
										<h4>Heures</h4>
										<div className={`input-container`}>
											<input
												type="text"
												name="heures"
												maxLength={3}
												size={3}
												id="heures"
												value={formule.heures_f}
												onChange={(e) => {
													if (isNaN(e.target.value))
														return;
													setFormules((prev) => {
														let newT = prev;
														newT[index].heures_f =
															e.target.value;
														return newT.map(
															(v) => v,
														);
													});
													setHasChanged(true);
												}}
											/>
										</div>
									</div>

									<div>
										<h4>Prix</h4>
										<div className={`input-container`}>
											<input
												type="text"
												name="prix"
												maxLength={5}
												size={5}
												id="prix"
												value={formule.prix}
												onChange={(e) => {
													if (isNaN(e.target.value))
														return;
													setFormules((prev) => {
														let newT = prev;
														newT[index].prix =
															e.target.value;
														return newT.map(
															(v) => v,
														);
													});
													setHasChanged(true);
												}}
											/>
										</div>
									</div>
								</div>
								<div className="flex justify-between items-center">
									<div className="flex items-center gap-1 opacity-75">
										<h3>
											Votre revenu estimé pour l'achat de
											cette formule :{" "}
										</h3>
										<h3 className="text-primary">
											{(0.9 * formule.prix).toFixed(2)}€
										</h3>
									</div>
									{formule.id_formule && (
										<div>
											<div
												className="button-secondary"
												onClick={async (e) => {
													setFormules((prev) => {
														let newT = prev;
														newT.splice(index, 1);
														return newT.map(
															(v) => v,
														);
													});
													await postAPI(
														`/user/deleteFormule`,
														{
															id_formule:
																formule.id_formule,
														},
													).then((data) => {
														if (
															data.affectedRows >
															0
														) {
															enqueueSnackbar(
																`${formule.titre} a été supprimée`,
																{
																	variant:
																		"success",
																},
															);
														} else {
															enqueueSnackbar(
																"Une erreur s'est produite.",
																{
																	variant:
																		"error",
																},
															);
														}
													});
												}}
											>
												Supprimer
											</div>
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
				<div className="flex justify-center items-center">
					<div
						className="button-primary w-fit"
						onClick={(e) => {
							setFormules((prev) => {
								let newT = prev;
								newT.push({
									titre: `Formule ${prev.length + 1}`,
									description_f: "",
									coach: auth.user.id,
									prix: "",
									type: "Ponctuel",
									heures_f: 1,
								});
								return newT.map((v) => v);
							});
							setHasChanged(true);
						}}
					>
						<div className="flex gap-1">
							<AddIcon />
							Ajouter
						</div>
					</div>
				</div>
			</div>

			<div
				className={` z-10 justify-center items-center shadow-xl fixed ${hasChanged ? "bottom-10" : "bottom-[-70px]"} duration-200 ease-in-out`}
			>
				<div className="bg-[#1B1B1B] w-fit rounded flex items-center justify-center gap-6 shadow-2xl py-2 px-6">
					{loadingChanges ? (
						<div className="flex items-center gap-4">
							<div className="flex items-center justify-center scale-90">
								<CircularProgress color="success" />
							</div>
							<h3>
								Veuillez patienter. Nous prenons en compte vos
								changements...
							</h3>
						</div>
					) : (
						<div className="flex items-center justify-center">
							<div>
								<h3>
									Des changements ont été effectués.
									Voulez-vous les enregistrer ?
								</h3>
							</div>
							<div className="flex items-center justify-center gap-3">
								<div
									className="w-fit bg-transparent text-white py-2 px-5 rounded hover:opacity-80 ease-in-out duration-150 cursor-pointer"
									onClick={(e) => {
										setHasChanged(false);
									}}
								>
									<h3 className="text-l">Annuler</h3>
								</div>
								<div
									className="w-fit bg-green-600 text-white py-2 px-5 rounded hover:opacity-80 ease-in-out duration-150 cursor-pointer"
									onClick={saveChanges}
								>
									<h3 className="text-l">Enregistrer</h3>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
