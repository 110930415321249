import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
	

	componentDidMount() {

	}

	render() {
		return (
			<div className='main-panel align-center w-full text-center mt-[200px] flex flex-col justify-between items-center gap-4'>
				<h1 className='text-[60px] font-bold leading-[67px] w-[70%]'>Page introuvable</h1>
				<h3 className='text-xl w-[70%]'>Il semblerait que l'URL que tu as entrée ne mène nulle part. Jette un œil...</h3>
				<h3 className='text-xl w-[70%] mt-4'>Sinon, tu peux retourner à l'accueil</h3>
				<Link to={'/home'} className='button-primary-large'>Bring me back home!</Link>
			</div>
		);
	}
}

export { NotFound };