import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import Dialog from "../utils/dialog";

import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "../auth";
import { getAPI, sendNotification } from "../utils/functions";
import { postAPI } from "../utils/functions";

const Cancel = ({ user }) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [demandes, setSeances] = useState([]);
	const [dialogContent, setDialogContent] = useState(null);

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);

	const fetchData = async () => {
		if (user) {
			await getAPI(`/user/seances/waitForCancel`).then(async (dat) => {
				setSeances(dat);
				setLoading(false);
			});
		}
	};

	useLayoutEffect(() => {
		fetchData();
	}, [user]);

	const handleCloseDialog = () => {
		setDialogContent(null);
	};

	if (loading)
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Demandes d'annulation
					</h3>
					<p className="text-white/75">
						Apparaissent ici les demandes d'annulation de séances
						validées. Si vous décidez de préserver la séance, alors
						elle aura bien lieu à la date prévue.
					</p>
				</div>
				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
			</div>
		);

	if (demandes && demandes.length > 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Demandes d'annulation
					</h3>
					<p className="text-white/75">
						Apparaissent ici les demandes d'annulation de séances
						validées. Si vous décidez de préserver la séance, alors
						elle aura bien lieu à la date prévue.
					</p>
				</div>

				<div className="flex flex-col gap-3">
					{demandes.map((demande) => {
						return (
							<div
								className="flex items-start justify-between gap-3 p-2 bg-[#1B1B1B] border-2 border-white/10 rounded"
								key={`demande-${demande.id}`}
							>
								{/* INFOS */}
								<div className="flex flex-col gap-1">
									<h3 className="font-sf-pro-e font-bold text-lg">
										{demande.formule.titre} -{" "}
										{moment(demande.date).format("ll")} à{" "}
										{moment(demande.date).format("HH[h]mm")}
									</h3>
									<h3 className="opacity-75 font-bold">
										{demande.member.pseudo}
									</h3>
									<br />
									<h3
										className="opacity-75 hover:cursor-pointer hover:text-primary text-slate-300 w-fit"
										onClick={() => {
											setDialogContent(demande);
										}}
									>
										Voir le récapitulatif
									</h3>
								</div>

								{/* BOUTONS */}
								<div className="flex items-center gap-2 flex-wrap justify-end m-2">
									<div
										className="button-secondary flex items-center"
										onClick={async (e) => {
											setConfirmLoading(true);
											await postAPI(
												`/user/seances/cancelSeance`,
												{
													id: demande.id,
													cancelReason:
														demande.cancelReason,
													facture: demande.facture,
												},
											)
												.then((dat) => {
													if (dat.affectedRows >= 1) {
														setSeances((prev) => {
															let newObj =
																prev.filter(
																	(s) =>
																		s.id !==
																		demande.id,
																);
															return newObj;
														});
														enqueueSnackbar(
															`La séance n°${demande.id} a été annulée`,
														);
														sendNotification(
															demande.member.id,
															`${demande.coach.pseudo} à confirmé l'annulation de la séance n°${demande.id}`,
															"profile#seances",
														);
													}
													setConfirmLoading(false);
												})
												.catch(() => {
													setConfirmLoading(false);
												});
										}}
									>
										{confirmLoading ? (
											<div className="flex items-center gap-1">
												<CircularProgress color="success" />
											</div>
										) : (
											<div className="flex items-center gap-1">
												<CheckIcon />
												Accepter
											</div>
										)}
									</div>
									<div
										className="button-secondary flex items-center"
										onClick={async (e) => {
											setCancelLoading(true);
											await postAPI(
												`/user/seances/confirmSeance`,
												{
													id: demande.id,
												},
											)
												.then((dat) => {
													if (dat.affectedRows >= 1) {
														setSeances((prev) => {
															let newObj =
																prev.filter(
																	(s) =>
																		s.id !==
																		demande.id,
																);
															return newObj;
														});
														enqueueSnackbar(
															`La demande d'annulation a été rejetée`,
														);
														sendNotification(
															demande.member.id,
															`${demande.coach.pseudo} à rejeté l'annulation pour la séance n°${demande.id}`,
															"profile#seances",
														);
													}
													setCancelLoading(false);
												})
												.catch(() => {
													setCancelLoading(false);
												});
										}}
									>
										{cancelLoading ? (
											<div className="flex items-center justify-center">
												<CircularProgress
													color="success"
													className="scale-90"
												/>
											</div>
										) : (
											<div className="flex items-center gap-1">
												<CloseIcon />
												Rejeter
											</div>
										)}
									</div>
									{/* <div className='button-secondary flex items-center' onClick={(e) => {
											
										}}><div className='flex items-center gap-1'><UpdateRoundedIcon />Reprogrammer</div></div> */}
								</div>

								{dialogContent && (
									<Dialog
										content={dialogContent}
										handleClose={handleCloseDialog}
										refreshFunction={fetchData}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	} else if (demandes && demandes.length === 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Demandes d'annulation
					</h3>
					<p className="text-white/75">
						Apparaissent ici les demandes d'annulation de séances
						validées. Si vous décidez de préserver la séance, alors
						elle aura bien lieu à la date prévue.
					</p>
				</div>

				<div
					className={`border-2 border-white/25 bg-[#1B1B1B] rounded relative p-4`}
				>
					<div className="text-center">
						<h3 className="text-xl">
							Vous n'avez aucune demande en attente
						</h3>
					</div>
				</div>
			</div>
		);
	}
};

export default Cancel;
