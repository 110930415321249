import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { getAPI } from "./utils/functions";

export default class Formules extends React.Component {
	state = {
		formules: [],
		selected: null,
		loading: true,
	};

	navigate = null;

	componentDidMount() {
		getAPI(`/formules/${this.props.coach}`).then((dat) => {
			if (dat.length >= 0) {
				this.setState({ formules: dat, loading: false });
			}
		});
	}

	render() {
		const { formules, selected, loading } = this.state;

		if (loading) {
			// RENDER LOADING
			return (
				<div className="w-full rounded bg-[#1B1B1B] p-4 flex flex-col gap-4 items-center">
					<div className="w-full flex justify-center">
						<CircularProgress color="success" />
					</div>
				</div>
			);
		}

		if (formules.length === 0) {
			return (
				<div className="w-full rounded bg-[#1B1B1B] p-4 flex flex-col gap-4 items-center">
					<div className="w-full flex justify-center font-bold text-lg">
						Aucun pack disponible
					</div>
				</div>
			);
		}

		return (
			<div className="w-fit rounded bg-[#1B1B1B] px-4 py-6 flex flex-col gap-4 justify-stretch items-center border-2 border-white/10">
				<div className="flex flex-wrap gap-4 w-fit min-w-fit max-w-fit">
					{formules.map((v, i) => {
						return (
							<div
								className="flex flex-1 flex-wrap flex-col gap-2 min-w-fit rounded border-[1px] border-white/20 p-2 hover:border-primary/70 hover:cursor-pointer"
								key={i}
								id={i}
								onClick={(e) => {
									this.setState({ selected: v.id_formule });
									var activePack =
										document.getElementsByClassName(
											"activePack",
										)[0];
									if (activePack) {
										activePack.classList.replace(
											"border-primary",
											"border-white/20",
										);
										activePack.classList.add(
											"hover:border-primary/70",
											"border-white/20",
										);
										activePack.classList.remove(
											"activePack",
										);
									}
									document.getElementById(i).className =
										document
											.getElementById(i)
											.className.replace(
												"border-white/20",
												"border-primary",
											)
											.replace(
												"hover:border-primary/70",
												"",
											) + " activePack";
								}}
							>
								{/* OPTION HEADER */}
								<div className="flex gap-1 font-bold items-center min-w-fit">
									<h4 className="whitespace-nowrap">
										{v.titre} -
									</h4>
									<h4 className="text-primary">
										{v.prix.toFixed(2)}€
									</h4>
								</div>

								{/* OPTION BODY */}
								<div>
									<h4>{v.description_f}</h4>
								</div>
							</div>
						);
					})}
				</div>
				{selected ? (
					<Link
						className="button-primary mt-2 max-w-lg"
						to={`/checkout/${selected}`}
					>
						Acheter ce pack
					</Link>
				) : (
					<button
						className="button-primary mt-2 max-w-lg"
						disabled="disabled"
					>
						Acheter ce pack
					</button>
				)}
			</div>
		);
	}
}
