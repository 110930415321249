import React from 'react';
import PulsingDot from './pulsingDot'

const SnackbarSeance = ({ title, text, onClick }) => {

	return (
		<div className='fixed bottom-[20px] right-[20px] z-48 w-fit'>
			<div id='snack' className='relative w-fit max-w-[367px] rounded-lg z-49 bg-[#242C32] flex items-center justify-center gap-4 px-4 py-3 cursor-pointer shadow-lg yellow-bottom-blur' onClick={onClick}>

				<div className='bg-[#303746] rounded-full w-8 p-6 px-8 flex justify-center items-center'>
					<PulsingDot />
				</div>
				<div className='flex flex-col'>
					<h3 className='text-xl font-sf-pro-e font-bold tracking leading-[22px]'>{title}</h3>
					<p className='text-lg text-[#C8C5C5] leading-[18px]'>{text}</p>
				</div>

			</div>
		</div>
	);
};

export default SnackbarSeance;