import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postAPI } from "./utils/functions";
import { enqueueSnackbar } from "notistack";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [emailExists, setEmailExists] = useState(false);
	const [currentHash, setCurrentHash] = useState("");

	useEffect(() => {
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);
	});

	useEffect(() => {
		postAPI(`/emailExists`, {
			email: email,
		}).then((dat) => {
			if (dat.length > 0) {
				setEmailExists(true);
			} else {
				setEmailExists(false);
			}
		});
	}, [email]);

	if (currentHash === "#sent") {
		return (
			<nav className="form">
				<div className="neon left-[-1000px] top-[-350px]"></div>
				<div className="neon right-[-1000px]"></div>

				<form className="scale-90">
					<span>
						<svg
							id="Calque_1"
							width="200px"
							data-name="Calque 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="186.25 492.32 707.5 95.38"
						>
							<path
								fill="#fff"
								d="m237.22,546.34l-8.08-5.79h.02l-25.57-18.66c-.56-13.36,2.75-12.8,14.19-12.35v12.04l19.43.18v-28.25c-13.58-1.03-41.24-4.94-48.81,10.74-3.63,6.4-1.38,22.16-1.99,29.42l8.08,5.79h-.02l25.57,18.66c.56,13.36-2.75,12.8-14.19,12.35v-12.04l-19.43-.18v28.25c13.76.91,41.24,4.94,48.81-10.74,3.63-6.4,1.38-22.16,1.99-29.42Z"
							></path>{" "}
							<path
								fill="#fff"
								d="m303.51,542.67v-50.21h-19.17v42.58c-5.39,4.56-10.08,8.27-14.06,11.14v-53.72h-19.43v95.04h19.43v-19.63c2.43-1.64,5.19-3.52,8.29-5.64l9.76,25.27h20.89l-13.93-37.67c3.01-2.43,5.75-4.82,8.23-7.16Z"
							></path>{" "}
							<polygon
								fill="#fff"
								points="317.36 512.07 336.86 512.07 336.86 568.11 317.36 568.11 317.36 587.61 375.92 587.61 375.92 568.11 356.42 568.11 356.42 512.07 375.92 512.07 375.92 492.57 317.36 492.57 317.36 512.07"
							></polygon>{" "}
							<polygon
								fill="#fff"
								points="428.41 568.18 408.98 568.18 408.98 492.57 389.55 492.57 389.55 587.61 447.84 587.61 447.84 548.75 428.41 548.75 428.41 568.18"
							></polygon>{" "}
							<polygon
								fill="#fff"
								points="500.33 568.18 480.9 568.18 480.9 492.57 461.47 492.57 461.47 587.61 519.77 587.61 519.77 548.75 500.33 548.75 500.33 568.18"
							></polygon>{" "}
							<path
								fill="#fff"
								d="m569.34,568.07h-1.25c-1.06.04-1.36,0-2.28,0-4.87,0-6.5.26-8.14-.77-2.43-1.53-4.83-4.29-4.83-9.2v-65.52h-19.43v73.42c0,3.98.92,7.61,2.75,10.88,1.83,3.27,4.5,5.88,7.99,7.82,3.49,1.94,7.69,2.92,12.6,2.92h32.03v-95.04h-19.43v75.5Z"
							></path>{" "}
							<path
								fill="#fff"
								d="m643.29,495.49c-3.47-1.94-7.68-2.92-12.64-2.92h-28.25v95.04h19.5v-16.7l32.16-28.53v-28.19c0-3.98-.93-7.6-2.78-10.87-1.86-3.27-4.52-5.88-7.99-7.83Zm-7.2,39.33c-5.16,4.17-9.81,7.99-14.19,11.27v-37.47h1.66c2.7,0,4.86.09,6.5.27,1.94.26,3.35.89,4.21,1.89.86,1,1.38,2.57,1.56,4.74.18,2.17.26,5.2.26,9.09v10.21Z"
							></path>{" "}
							<polygon
								fill="#53FC1A"
								points="893.72 492.57 874.22 492.57 874.22 563.7 861.62 549.84 861.62 492.57 841.98 492.57 842.13 504.28 842.13 549.84 829.53 563.7 829.53 492.57 810.03 492.57 810.03 587.61 829.99 587.38 842.13 575.45 851.87 565.32 851.87 565.32 861.62 575.45 873.76 587.38 893.72 587.61 893.72 492.57"
							></polygon>{" "}
							<polygon
								fill="#53FC1A"
								points="706.03 537.34 686.86 492.51 667.69 492.51 667.69 587.68 686.86 587.68 686.86 541.69 706.03 587.68 725.19 587.68 725.19 492.51 706.03 492.51 706.03 537.34"
							></polygon>{" "}
							<path
								fill="#53FC1A"
								d="m757.99,492.51h-19.17v95.17h57.5v-95.17h-38.34Zm19.17,76h-19.17v-56.83h19.17v56.83Z"
							></path>
						</svg>
						<br />
						<h3 className="text-2xl font-bold mt-10">
							Nous avons envoyé un lien de réinitialisation à{" "}
							{email}
						</h3>

						<h4 className="mt-4 text-sm">
							Vous vous souvenez soudainement de votre mot de
							passe ?{" "}
							<Link
								to={"/login"}
								className="text-primary font-bold"
							>
								Connectez-vous
							</Link>
						</h4>
						<h4 className="mt-2 text-sm">
							Pas encore de compte ?{" "}
							<Link
								to={"/register"}
								className="text-primary font-bold"
							>
								Créez-en un
							</Link>
						</h4>
					</span>
				</form>
			</nav>
		);
	}

	return (
		<nav className="form">
			<div className="neon left-[-1000px] top-[-350px]"></div>
			<div className="neon right-[-1000px]"></div>

			<form className="scale-90">
				<span>
					<svg
						id="Calque_1"
						width="200px"
						data-name="Calque 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="186.25 492.32 707.5 95.38"
					>
						<path
							fill="#fff"
							d="m237.22,546.34l-8.08-5.79h.02l-25.57-18.66c-.56-13.36,2.75-12.8,14.19-12.35v12.04l19.43.18v-28.25c-13.58-1.03-41.24-4.94-48.81,10.74-3.63,6.4-1.38,22.16-1.99,29.42l8.08,5.79h-.02l25.57,18.66c.56,13.36-2.75,12.8-14.19,12.35v-12.04l-19.43-.18v28.25c13.76.91,41.24,4.94,48.81-10.74,3.63-6.4,1.38-22.16,1.99-29.42Z"
						></path>{" "}
						<path
							fill="#fff"
							d="m303.51,542.67v-50.21h-19.17v42.58c-5.39,4.56-10.08,8.27-14.06,11.14v-53.72h-19.43v95.04h19.43v-19.63c2.43-1.64,5.19-3.52,8.29-5.64l9.76,25.27h20.89l-13.93-37.67c3.01-2.43,5.75-4.82,8.23-7.16Z"
						></path>{" "}
						<polygon
							fill="#fff"
							points="317.36 512.07 336.86 512.07 336.86 568.11 317.36 568.11 317.36 587.61 375.92 587.61 375.92 568.11 356.42 568.11 356.42 512.07 375.92 512.07 375.92 492.57 317.36 492.57 317.36 512.07"
						></polygon>{" "}
						<polygon
							fill="#fff"
							points="428.41 568.18 408.98 568.18 408.98 492.57 389.55 492.57 389.55 587.61 447.84 587.61 447.84 548.75 428.41 548.75 428.41 568.18"
						></polygon>{" "}
						<polygon
							fill="#fff"
							points="500.33 568.18 480.9 568.18 480.9 492.57 461.47 492.57 461.47 587.61 519.77 587.61 519.77 548.75 500.33 548.75 500.33 568.18"
						></polygon>{" "}
						<path
							fill="#fff"
							d="m569.34,568.07h-1.25c-1.06.04-1.36,0-2.28,0-4.87,0-6.5.26-8.14-.77-2.43-1.53-4.83-4.29-4.83-9.2v-65.52h-19.43v73.42c0,3.98.92,7.61,2.75,10.88,1.83,3.27,4.5,5.88,7.99,7.82,3.49,1.94,7.69,2.92,12.6,2.92h32.03v-95.04h-19.43v75.5Z"
						></path>{" "}
						<path
							fill="#fff"
							d="m643.29,495.49c-3.47-1.94-7.68-2.92-12.64-2.92h-28.25v95.04h19.5v-16.7l32.16-28.53v-28.19c0-3.98-.93-7.6-2.78-10.87-1.86-3.27-4.52-5.88-7.99-7.83Zm-7.2,39.33c-5.16,4.17-9.81,7.99-14.19,11.27v-37.47h1.66c2.7,0,4.86.09,6.5.27,1.94.26,3.35.89,4.21,1.89.86,1,1.38,2.57,1.56,4.74.18,2.17.26,5.2.26,9.09v10.21Z"
						></path>{" "}
						<polygon
							fill="#53FC1A"
							points="893.72 492.57 874.22 492.57 874.22 563.7 861.62 549.84 861.62 492.57 841.98 492.57 842.13 504.28 842.13 549.84 829.53 563.7 829.53 492.57 810.03 492.57 810.03 587.61 829.99 587.38 842.13 575.45 851.87 565.32 851.87 565.32 861.62 575.45 873.76 587.38 893.72 587.61 893.72 492.57"
						></polygon>{" "}
						<polygon
							fill="#53FC1A"
							points="706.03 537.34 686.86 492.51 667.69 492.51 667.69 587.68 686.86 587.68 686.86 541.69 706.03 587.68 725.19 587.68 725.19 492.51 706.03 492.51 706.03 537.34"
						></polygon>{" "}
						<path
							fill="#53FC1A"
							d="m757.99,492.51h-19.17v95.17h57.5v-95.17h-38.34Zm19.17,76h-19.17v-56.83h19.17v56.83Z"
						></path>
					</svg>
					<br />
					<h3 className="text-3xl font-bold mt-10">
						Mot de passe oublié
					</h3>
					<p className="leading-[24px] opacity-70">
						Nous vous enverrons un lien à l'adresse indiqué pour
						réinitaliser votre mot de passe
					</p>

					<div className="mt-[20px]">
						<h4>Email</h4>
						<div className={`input-container`}>
							<input
								type="email"
								name="email"
								id="email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</div>
					</div>

					{emailExists ? (
						<button
							className="button-primary mt-4 text-lg w-full"
							onClick={async (e) => {
								e.preventDefault();
								await postAPI(`/resetPassword`, {
									email: email,
								}).then((res) => {
									if (!res) return;
									setCurrentHash("#sent");
									enqueueSnackbar(
										`Un mail a été envoyé pour réinitialiser votre mot de passe !`,
										{ variant: "success" },
									);
								});
								//sendMailNotification(email, "Réinitialiser votre mot de passe", renderToString(<ForgetPassword pseudo={"Randy"} lien={"https://skillupnow.xyz"} />))
								//setCurrentHash('#sent')
							}}
						>
							Confirmer
						</button>
					) : (
						<button
							disabled
							className="button-secondary mt-4 text-lg w-full"
						>
							Aucun compte avec cet email
						</button>
					)}

					<h4 className="mt-4 text-sm">
						Vous vous souvenez soudainement de votre mot de passe ?{" "}
						<Link to={"/login"} className="text-primary font-bold">
							Connectez-vous
						</Link>
					</h4>
					<h4 className="mt-2 text-sm">
						Pas encore de compte ?{" "}
						<Link
							to={"/register"}
							className="text-primary font-bold"
						>
							Créez-en un
						</Link>
					</h4>
				</span>
			</form>
		</nav>
	);
};

export default ForgotPassword;
