import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { useNavigate } from "react-router-dom";
import { getAPI, postAPI } from "./utils/functions";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "./utils/avatar";
import Chat from "./utils/chat";

const ChatContainer = ({ openChatContact = null, socket }) => {
	const auth = useAuth();
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [contacts, setContacts] = useState([]);
	const [openChat, setOpenChat] = useState(openChatContact);

	const stylizing =
		"flex gap-4 py-3 px-6 bg-[#1B1B1B] items-center rounded box-border hover:cursor-pointer";

	useEffect(() => {
		setIsLoading(true);
		auth.authenticate().then((r) => {
			setUser(r);
			setIsLoading(false);
		});
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);
	}, []);

	useEffect(() => {
		if (!socket) return;
		if (contacts.length < 1) return;

		socket.emit("getOnlines", (res) => {
			contacts.forEach((contact, i) => {
				if (res.includes(contact.id.toString())) {
					let v = [...contacts];
					v[i].online = true;
					setContacts(v);
				}
			});
		});

		socket.on(`new online`, (newUSer) => {
			contacts.forEach((contact, i) => {
				if (newUSer === contact.id.toString()) {
					let v = [...contacts];
					v[i].online = true;
					setContacts(v);
				}
			});
		});

		socket.on(`new offline`, (newUSer) => {
			contacts.forEach((contact, i) => {
				if (newUSer === contact.id.toString()) {
					let v = [...contacts];
					v[i].online = false;
					setContacts(v);
				}
			});
		});
	}, [socket, contacts]);

	useEffect(() => {
		if (!user) return;
		getAPI(`/user/getContacts`).then((res) => {
			setContacts(res);
		});
	}, [user]);

	useEffect(() => {
		if (!openChat) return;
		postAPI(`/user/addContact`, {
			user: auth.user.id,
			contact: openChat.id,
		});
	}, [openChat]);

	const getOpenChat = () => {
		if (!openChat) {
			return (
				<div
					className={`flex justify-center items-center h-full mt-10`}
				>
					<h1 className={`text-2xl opacity-75`}>
						Ouvre un chat pour commencer à discuter !
					</h1>
				</div>
			);
		}
		return (
			<div className={`flex justify-center items-center h-full w-full`}>
				<Chat openChat={openChat} socket={socket} />
			</div>
		);
	};

	if (isLoading)
		return (
			<div className="flex flex-col gap-5 w-full items-center absolute top-1/3 scale-125">
				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
				<h3>Chargement de votre messagerie ...</h3>
			</div>
		);

	if (contacts.length > 0) {
		return (
			<span className="container flex items-start justify-center h-96 w-full px-[150px] mb-[50px]">
				<div className="box-border min-w-[305px]">
					<div className="flex flex-col gap-3 m-6 mr-10 ml-0 box-border">
						{contacts.map((contact) => {
							return (
								<div
									className={stylizing + " active-section"}
									key={contact.pseudo}
									onClick={(e) => {
										setOpenChat(contact);
									}}
								>
									<Avatar
										image={contact.image}
										size={50}
										radius={"rounded-full"}
									/>
									<div className={`flex flex-col`}>
										<h2 className={"text-lg font-bold"}>
											{contact.pseudo}
										</h2>
										<h3 className={`opacity-75`}>
											<div
												className={`flex items-center gap-2`}
											>
												{contact.online ? (
													<Fragment>
														<div
															className={`w-2 h-2 bg-primary/50 rounded-full`}
														></div>
														<h3>En ligne</h3>
													</Fragment>
												) : (
													<Fragment>
														<div
															className={`w-2 h-2 bg-white/50 rounded-full`}
														></div>
														<h3>Hors ligne</h3>
													</Fragment>
												)}
											</div>
										</h3>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="grow border-l-2 border-white/30 min-h-full flex flex-col">
					<div className="gap-2 m-6 mx-6 mr-0 min-h-full w-full">
						{getOpenChat()}
					</div>
				</div>
			</span>
		);
	} else {
		return (
			<span className="container flex items-start justify-center h-[30rem] w-full px-[150px] mb-[50px]">
				<h1 className={`font-2xl`}>
					Vous n'avez aucun contact pour le moment
				</h1>
			</span>
		);
	}
};
export default ChatContainer;
