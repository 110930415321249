import { createContext, useState, useContext } from "react";
import { getAPI, postAPI } from "./utils/functions";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({
		id: localStorage.getItem("id"),
		pseudo: localStorage.getItem("pseudo"),
		image: localStorage.getItem("image"),
		discord:
			localStorage.getItem("discord") !== "undefined"
				? JSON.parse(localStorage.getItem("discord"))
				: null,
		coach: localStorage.getItem("coach"),
	});

	const login = (user) => {
		setUser(user);
		localStorage.setItem("id", user.id);
		localStorage.setItem("pseudo", user.pseudo);
		/*user.image.text((blob) => {
			localStorage.setItem("image", blob);
		});*/
		localStorage.setItem("image", user.image);
		localStorage.setItem("discord", JSON.stringify(user.discord));
		localStorage.setItem("coach", user.coach);
		console.info(`[AUTH] => Logged as ${user.pseudo} (${user.id})`);
	};

	const update = (user) => {
		setUser(user);
		localStorage.setItem("id", user.id);
		localStorage.setItem("pseudo", user.pseudo);
		localStorage.setItem("image", user.image);
		localStorage.setItem("coach", user.coach);
		localStorage.setItem("discord", JSON.stringify(user.discord));
	};

	const logout = async () => {
		setUser({
			id: null,
			pseudo: null,
			image: null,
		});
		localStorage.removeItem("id");
		localStorage.removeItem("pseudo");
		localStorage.removeItem("image");
		localStorage.removeItem("discord");
		localStorage.removeItem("coach");
		await postAPI(`/logout`).then((data) => {
			if (data.success) {
				console.info(
					`[AUTH] => ${user.pseudo} (${user.id}) logged out`,
				);
			}
		});
	};

	const authenticate = async () => {
		return new Promise(async (resolve, reject) => {
			await getAPI(`/user/me`).then((res) => {
				if (!res.error) {
					resolve(res);
				}
			});
		});
	};

	return (
		<AuthContext.Provider
			value={{ user, login, logout, update, authenticate }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
