import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getAPI, postAPI } from "./utils/functions";

const ConfirmCheckout = () => {
	const { facture } = useParams();
	const [order, setOrder] = useState(null);
	const [coach, setCoach] = useState(null);
	const [formule, setFormule] = useState(null);

	useEffect(() => {
		getAPI(`/user/credit/${facture}`).then((dat) => {
			if (!dat.error) {
				let o = dat.result;
				setOrder(o);
				setCoach(o.coach);
				setFormule(o.formule);
			}
		});
	}, []);

	if (order && coach && formule) {
		return (
			<div className="flex flex-col mx-36 mt-36 gap-10 items-center justify-center">
				<h1 className="text-3xl font-sf-pro-e font-bold">
					Commande effectuée avec succès ✅
				</h1>
				<h3 className="text-lg">
					Votre achat s'est déroulé sans pépin. Vous venez de recevoir{" "}
					{formule.heures_f} heure(s) de crédit avec{" "}
					<Link
						to={`/coachs/${coach.pseudo}`}
						className="text-primary"
					>
						{coach.pseudo}
					</Link>{" "}
				</h3>
				<h3 className="text-lg">
					Vous pouvez commencer à utiliser vos crédits dès maintenant
					depuis{" "}
					<Link to={`/profile#credits`} className="text-primary">
						votre profil
					</Link>
					.
				</h3>
			</div>
		);
	} else {
		return (
			<div className="w-full flex justify-center">
				<CircularProgress color="success" />
			</div>
		);
	}
};

export default ConfirmCheckout;
