import React from "react";

const Avatar = ({
	image = null,
	size = 50 | 100 | 200 | 250,
	radius = "rounded" | "rounded-full" | "rounded-md",
}) => {
	if (!image || image === "null") {
		return (
			<img
				src={require(`../../assets/avatar-${size}.svg`)}
				width={size}
				height={size}
				alt="Avatar"
				className={radius + " bg-black/25"}
			/>
		);
	} else {
		return (
			<img
				src={`${image}`}
				width={size}
				height={size}
				alt="Avatar"
				className={radius}
			/>
		);
	}
};
export default Avatar;
