import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "./checkbox";
import { postAPI } from "./utils/functions";

export const DiscordAuth = () => {
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [confirmPwd, setConfPwd] = useState("");
	const [pseudo, setPseudo] = useState("");

	const [refreshToken, setRefreshToken] = useState(null);
	const [discordEmail, setDiscordEmail] = useState(null);

	const [tos, setTos] = useState(false);

	const [errorEmail, setErrorEmail] = useState("");
	const [errorPwd, setErrorPwd] = useState("");
	const [errorPwdFirst, setErrorPwdFirst] = useState("");
	const [errorTos, setErrorTos] = useState("");
	const [messageTip, setMessageTip] = useState("");

	const [userLoggedIn, setUserLoggedIn] = useState(false);

	const navigate = useNavigate();

	const client_id = process.env.REACT_APP_CLIENT_ID;
	const client_secret = process.env.REACT_APP_CLIENT_SECRET;
	const scopes = process.env.REACT_APP_SCOPES;
	const redirect_uri = process.env.REACT_APP_DISCORD_RED_URI_SIGNUP;

	const requestRegister = async () => {
		await postAPI(`/signup/discord`, {
			pseudo: pseudo,
			email: email,
			pwd: pwd,
			refresh_token: refreshToken,
			discord_email: discordEmail,
		}).then(() => {
			setUserLoggedIn(true);
		});
	};

	const requestEmailExists = async () => {
		let ret = false;
		await postAPI(`/emailExists`, {
			email: email,
		}).then((dat) => {
			if (dat.length > 0) {
				setErrorEmail("email-already-exists");
				ret = true;
			}
		});
		return ret;
	};

	const requestPseudoExists = async () => {
		let ret = false;
		await postAPI(`/pseudoExists`, {
			pseudo: pseudo,
		}).then((dat) => {
			if (dat.length > 0) {
				setPseudo(
					`${pseudo}${dat.length}${(Math.random() * 10).toFixed(0)}`,
				);
			}
		});
		return ret;
	};

	useEffect(() => {
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);

		/* Submit on enter */
		document.addEventListener("keyup", (e) => {
			if (e.key === "Enter") {
				handleRegister();
			}
		});

		/* Check if link is OK and get token*/
		if (window.location.search) {
			const fragment = new URLSearchParams(window.location.search);
			const code = fragment.get("code");
			if (!code) {
				navigate("/signup", { replace: true });
				return;
			}

			fetch(`https://discord.com/api/oauth2/token`, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					client_id: client_id,
					client_secret: client_secret,
					code: code,
					grant_type: "authorization_code",
					redirect_uri: redirect_uri,
					scope: scopes,
				}),
			})
				.then((res) =>
					res.json().then((dat) => {
						if (dat.error) {
							navigate("/signup", { replace: true });
						}
						setRefreshToken(dat.refresh_token);
						fetch(`https://discord.com/api/users/@me`, {
							method: "GET",
							headers: {
								"Content-type": "application/json",
								Authorization: `${dat.token_type} ${dat.access_token}`,
							},
						})
							.then((re) =>
								re.json().then((data) => {
									setDiscordEmail(data.email);
									setEmail(data.email);
									setPseudo(data.username);
								}),
							)
							.catch((erro) => {
								if (erro.request) {
									console.warn(
										"Error while request : ",
										erro.request,
										"Complete error : ",
										erro,
									);
								} else if (erro.response) {
									console.warn(
										"Error while response : ",
										erro.response,
										"Complete error : ",
										erro,
									);
								} else {
									console.warn(
										"Error while setting up request : ",
										erro,
									);
								}
							});
					}),
				)
				.catch((err) => {
					if (err.request) {
						console.warn(
							"Error while request : ",
							err.request,
							"Complete error : ",
							err,
						);
					} else if (err.response) {
						console.warn(
							"Error while response : ",
							err.response,
							"Complete error : ",
							err,
						);
					} else {
						console.warn("Error while setting up request : ", err);
					}
				});
		} else {
			navigate("/signup", { replace: true });
		}
	}, []);

	useEffect(() => {
		emailIsCorrect();
		requestEmailExists();
	}, [email]);

	useEffect(() => {
		requestPseudoExists();
	}, [pseudo]);

	useEffect(() => {
		passwordsMatch();
	}, [pwd, confirmPwd]);

	const noEmptyFields = () => {
		if (
			email.length !== 0 &&
			pwd.length !== 0 &&
			confirmPwd.length !== 0 &&
			pseudo.length !== 0
		) {
			return true;
		}
		setMessageTip(
			`before:absolute before:block before:content-[attr(before)] before:text-red-500 before:-top-8 before:left-0`,
		);
	};

	const emailIsCorrect = () => {
		if (!email) return false;
		if (email.length > 0) {
			if (
				email.indexOf("@", 1) !== -1 &&
				email.includes(".", email.indexOf("@", 1) + 2) &&
				email.slice(-1) !== "."
			) {
				setErrorEmail("input-valid");
				return true;
			}
			requestEmailExists();
			setErrorEmail("input-error-email");
		}
	};

	const passwordsMatch = () => {
		setErrorPwdFirst("");
		if (pwd.length > 0) {
			if (pwd === confirmPwd && pwd.length >= 5) {
				setErrorPwdFirst("input-valid");
				setErrorPwd("input-valid");
				return true;
			} else {
				if (pwd.length < 5) {
					setErrorPwdFirst("input-error-pwd-first");
					return false;
				} else {
					setErrorPwdFirst("input-valid");
				}
				if (pwd !== confirmPwd) {
					setErrorPwd("input-error-pwd");
					return false;
				}
				return false;
			}
		}
		if (pwd.length === 0) {
			setErrorPwd("");
			return false;
		}
	};

	const isTosAccepted = () => {
		if (tos) {
			return true;
		} else {
			setErrorTos("checkbox-error");
			return false;
		}
	};

	const handleRegister = async () => {
		if (
			noEmptyFields() &&
			emailIsCorrect() &&
			passwordsMatch() &&
			isTosAccepted()
		) {
			var reqCorrect = await requestEmailExists();
			var PreqCorrect = await requestPseudoExists();
			if (!reqCorrect && !PreqCorrect) requestRegister();
		}
	};

	if (userLoggedIn) {
		return (
			<nav className="form">
				<div className="neon left-[-1000px] top-[-350px]"></div>
				<div className="neon right-[-1000px]"></div>

				<form className="scale-90">
					<span>
						<h3 className="text-3xl font-bold mb-2">
							Votre compte a bien été créé ✅
						</h3>
						<p className="leading-[24px] opacity-70">
							Vous pouvez maintenant vous connecter
						</p>
					</span>
				</form>
			</nav>
		);
	}

	if (errorEmail === "email-already-exists") {
		return (
			<nav className="form">
				<div className="neon left-[-1000px] top-[-350px]"></div>
				<div className="neon right-[-1000px]"></div>

				<form className="scale-90">
					<span>
						<h3 className="text-3xl font-bold mb-2">
							Adresse email déjà liée 😕
						</h3>
						<p className="leading-[24px] opacity-70">
							Il semblerait que l'adresse email liée à votre
							compte Discord soit déjà liée à un compte
							SkillUpNow...
						</p>
						<h4 className="mt-3 text-l">
							Essayez de vous{" "}
							<Link
								to={"/login"}
								className="text-primary font-bold"
							>
								connecter
							</Link>
						</h4>
					</span>
				</form>
			</nav>
		);
	}

	if (errorEmail === "email-error-email") {
		return (
			<nav className="form">
				<div className="neon left-[-1000px] top-[-350px]"></div>
				<div className="neon right-[-1000px]"></div>

				<form className="scale-90">
					<span>
						<h3 className="text-3xl font-bold mb-2">
							Nous avons un soucis avec votre adresse mail 😕
						</h3>
						<p className="leading-[24px] opacity-70">
							Il semblerait que l'adresse email liée à votre
							compte Discord soit incorrecte...
						</p>
						<h4 className="mt-3 text-l">
							Essayez de la modifier et réessayez
						</h4>
					</span>
				</form>
			</nav>
		);
	}

	return (
		<nav className="form">
			<div className="neon left-[-1000px] top-[-350px]"></div>
			<div className="neon right-[-1000px]"></div>

			<form className="scale-90">
				<span>
					<svg
						id="Calque_1"
						width="200px"
						data-name="Calque 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="186.25 492.32 707.5 95.38"
					>
						<path
							fill="#fff"
							d="m237.22,546.34l-8.08-5.79h.02l-25.57-18.66c-.56-13.36,2.75-12.8,14.19-12.35v12.04l19.43.18v-28.25c-13.58-1.03-41.24-4.94-48.81,10.74-3.63,6.4-1.38,22.16-1.99,29.42l8.08,5.79h-.02l25.57,18.66c.56,13.36-2.75,12.8-14.19,12.35v-12.04l-19.43-.18v28.25c13.76.91,41.24,4.94,48.81-10.74,3.63-6.4,1.38-22.16,1.99-29.42Z"
						></path>{" "}
						<path
							fill="#fff"
							d="m303.51,542.67v-50.21h-19.17v42.58c-5.39,4.56-10.08,8.27-14.06,11.14v-53.72h-19.43v95.04h19.43v-19.63c2.43-1.64,5.19-3.52,8.29-5.64l9.76,25.27h20.89l-13.93-37.67c3.01-2.43,5.75-4.82,8.23-7.16Z"
						></path>{" "}
						<polygon
							fill="#fff"
							points="317.36 512.07 336.86 512.07 336.86 568.11 317.36 568.11 317.36 587.61 375.92 587.61 375.92 568.11 356.42 568.11 356.42 512.07 375.92 512.07 375.92 492.57 317.36 492.57 317.36 512.07"
						></polygon>{" "}
						<polygon
							fill="#fff"
							points="428.41 568.18 408.98 568.18 408.98 492.57 389.55 492.57 389.55 587.61 447.84 587.61 447.84 548.75 428.41 548.75 428.41 568.18"
						></polygon>{" "}
						<polygon
							fill="#fff"
							points="500.33 568.18 480.9 568.18 480.9 492.57 461.47 492.57 461.47 587.61 519.77 587.61 519.77 548.75 500.33 548.75 500.33 568.18"
						></polygon>{" "}
						<path
							fill="#fff"
							d="m569.34,568.07h-1.25c-1.06.04-1.36,0-2.28,0-4.87,0-6.5.26-8.14-.77-2.43-1.53-4.83-4.29-4.83-9.2v-65.52h-19.43v73.42c0,3.98.92,7.61,2.75,10.88,1.83,3.27,4.5,5.88,7.99,7.82,3.49,1.94,7.69,2.92,12.6,2.92h32.03v-95.04h-19.43v75.5Z"
						></path>{" "}
						<path
							fill="#fff"
							d="m643.29,495.49c-3.47-1.94-7.68-2.92-12.64-2.92h-28.25v95.04h19.5v-16.7l32.16-28.53v-28.19c0-3.98-.93-7.6-2.78-10.87-1.86-3.27-4.52-5.88-7.99-7.83Zm-7.2,39.33c-5.16,4.17-9.81,7.99-14.19,11.27v-37.47h1.66c2.7,0,4.86.09,6.5.27,1.94.26,3.35.89,4.21,1.89.86,1,1.38,2.57,1.56,4.74.18,2.17.26,5.2.26,9.09v10.21Z"
						></path>{" "}
						<polygon
							fill="#53FC1A"
							points="893.72 492.57 874.22 492.57 874.22 563.7 861.62 549.84 861.62 492.57 841.98 492.57 842.13 504.28 842.13 549.84 829.53 563.7 829.53 492.57 810.03 492.57 810.03 587.61 829.99 587.38 842.13 575.45 851.87 565.32 851.87 565.32 861.62 575.45 873.76 587.38 893.72 587.61 893.72 492.57"
						></polygon>{" "}
						<polygon
							fill="#53FC1A"
							points="706.03 537.34 686.86 492.51 667.69 492.51 667.69 587.68 686.86 587.68 686.86 541.69 706.03 587.68 725.19 587.68 725.19 492.51 706.03 492.51 706.03 537.34"
						></polygon>{" "}
						<path
							fill="#53FC1A"
							d="m757.99,492.51h-19.17v95.17h57.5v-95.17h-38.34Zm19.17,76h-19.17v-56.83h19.17v56.83Z"
						></path>
					</svg>
					<h3 className="text-3xl font-bold mt-10 mb-2">
						Inscription avec Discord
					</h3>
					<p className="leading-[24px] opacity-70">
						Choisissez un mot de passe pour votre compte. Suite à
						cela, vos comptes SKillUpNow et Discord seront liés.
						Vous pouvez changer cela à tout moment dans vos
						paramètres.
					</p>

					{/* <div className='mt-[30px]'>
							<h4>Pseudo</h4>
							<input className={`${errorPseudo}`} type="text" name="pseudo" id="pseudo" value={pseudo} onChange={(e) => {
								setPseudo(e.target.value);
								setMessageTip('')
							}} />
						</div>

						<div className='mt-[20px]'>
							<h4>Email</h4>
							<input className={`${errorEmail}`} type="email" name="email" id="email" value={email} onChange={(e) => {
								setEmail(e.target.value);
								setMessageTip('')
							}} />
						</div> */}

					<div className="mt-[20px]">
						<h4>Mot de passe - au moins 5 caractères</h4>
						<div className={`input-container ${errorPwdFirst}`}>
							<input
								type="password"
								name="password"
								id="password"
								value={pwd}
								onChange={(e) => {
									setPwd(e.target.value);
									setMessageTip("");
								}}
							/>
						</div>
					</div>

					<div className="mt-[20px]">
						<h4>Confirmer le mot de passe</h4>
						<div className={`input-container ${errorPwd}`}>
							<input
								type="password"
								name="confirmPassword"
								id="confirmPassword"
								value={confirmPwd}
								onChange={(e) => {
									setConfPwd(e.target.value);
									setMessageTip("");
								}}
							/>
						</div>
					</div>

					<div className="flex justify-between w-full mt-4 items-center">
						<div className="flex justify-left gap-2 items-center">
							<Checkbox
								className={`scale-110 ${errorTos}`}
								checked={tos}
								onChange={(e) => {
									setTos(e.target.checked);
									setErrorTos("");
								}}
							/>
							<p className="opacity-90 text-sm">
								J'ai lu et j'accepte les{" "}
								<Link to={"/cgu"} className="text-primary">
									Conditions d'utilisation
								</Link>
							</p>
						</div>
					</div>

					<button
						before={"🛈 Tous les champs doivent être complétés"}
						className={`${messageTip} button-primary-large mt-10 font-thin w-full relative`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleRegister();
						}}
					>
						Créer un compte
					</button>
				</span>
			</form>
		</nav>
	);
};
