import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Navigate, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import VerifiedIcon from "@mui/icons-material/Verified";
import Review from "./review";
import Formules from "./formuleTable";
import { getAPI, postAPI } from "./utils/functions";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { useAuth } from "./auth";
import Avatar from "./utils/avatar";

const Coach = (props) => {
	const { coachParam } = useParams();
	const auth = useAuth();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [coach, setCoach] = useState(null);
	const [reseaux, setReseaux] = useState({});
	const [links, setLinks] = useState({});
	const [languages, setLanguages] = useState([]);
	const [niveau, setNiveau] = useState("Débutant");
	const twitchIcon = (
		<svg
			width="30px"
			viewBox="0 0 24.00 24.00"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#ffffff"
			strokeWidth="0.00024000000000000003"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="#CCCCCC"
				strokeWidth="0.192"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.54689 1L1 4.77575V20.2087H6.49952V23H9.5933L12.5153 20.2087H16.9842L23 14.4622V1H2.54689V1ZM20.9368 13.4777L17.4995 16.7612H12L9.07799 19.5524V16.7612H4.43732V2.96992H20.9368V13.4777ZM17.4995 6.74658V12.4868H15.4373V6.74658H17.4995ZM12 6.74658V12.4868H9.9378V6.74658H12Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const discordIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M18.8944 4.34399C17.5184 3.71467 16.057 3.256 14.5317 3C14.3397 3.33067 14.1263 3.77866 13.977 4.13067C12.3546 3.89599 10.7439 3.89599 9.14394 4.13067C8.9946 3.77866 8.77059 3.33067 8.58925 3C7.05328 3.256 5.59194 3.71467 4.22555 4.34399C1.46289 8.41865 0.716219 12.3973 1.08955 16.3226C2.92421 17.6559 4.6949 18.4666 6.43463 19C6.86129 18.424 7.2453 17.8053 7.57597 17.1546C6.94663 16.92 6.3493 16.632 5.7733 16.2906C5.92263 16.184 6.07197 16.0667 6.21064 15.9493C9.68796 17.5387 13.4544 17.5387 16.889 15.9493C17.0383 16.0667 17.177 16.184 17.3263 16.2906C16.7503 16.632 16.153 16.92 15.5237 17.1546C15.8543 17.8053 16.2384 18.424 16.665 19C18.4037 18.4666 20.185 17.6559 22.0101 16.3226C22.4687 11.7787 21.2837 7.83202 18.8944 4.34399ZM8.05596 13.9013C7.01061 13.9013 6.15728 12.952 6.15728 11.7893C6.15728 10.6267 6.98928 9.67731 8.05596 9.67731C9.11194 9.67731 9.97591 10.6267 9.95457 11.7893C9.95457 12.952 9.11194 13.9013 8.05596 13.9013ZM15.065 13.9013C14.0197 13.9013 13.1653 12.952 13.1653 11.7893C13.1653 10.6267 13.9983 9.67731 15.065 9.67731C16.121 9.67731 16.985 10.6267 16.9637 11.7893C16.9637 12.952 16.1317 13.9013 15.065 13.9013Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const youtubeIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20.5949 4.45999C21.5421 4.71353 22.2865 5.45785 22.54 6.40501C22.9982 8.12002 23 11.7004 23 11.7004C23 11.7004 23 15.2807 22.54 16.9957C22.2865 17.9429 21.5421 18.6872 20.5949 18.9407C18.88 19.4007 12 19.4007 12 19.4007C12 19.4007 5.12002 19.4007 3.405 18.9407C2.45785 18.6872 1.71353 17.9429 1.45999 16.9957C1 15.2807 1 11.7004 1 11.7004C1 11.7004 1 8.12002 1.45999 6.40501C1.71353 5.45785 2.45785 4.71353 3.405 4.45999C5.12002 4 12 4 12 4C12 4 18.88 4 20.5949 4.45999ZM15.5134 11.7007L9.79788 15.0003V8.40101L15.5134 11.7007Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);
	const twitterIcon = (
		<svg
			width="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				{" "}
				<path
					d="M22 5.9246C21.2645 6.25051 20.4744 6.47071 19.6438 6.57025C20.4911 6.06289 21.1412 5.25782 21.4477 4.29948C20.655 4.76984 19.7768 5.1116 18.8422 5.29481C18.0935 4.49855 17.0277 4 15.8474 4C13.582 4 11.7446 5.8374 11.7446 8.10464C11.7446 8.42526 11.7816 8.73707 11.8503 9.03832C8.43883 8.86656 5.41672 7.23263 3.39258 4.75046C3.04025 5.35823 2.83766 6.06289 2.83766 6.81335C2.83766 8.23677 3.56258 9.4937 4.66273 10.2292C3.98978 10.2072 3.35735 10.0231 2.80419 9.71567V9.76852C2.80419 11.7565 4.21792 13.4151 6.09583 13.7921C5.75055 13.8855 5.38853 13.9348 5.01506 13.9348C4.75081 13.9348 4.49273 13.9102 4.24258 13.8626C4.76491 15.4921 6.27993 16.6795 8.07593 16.7112C6.67101 17.8122 4.90144 18.4684 2.97948 18.4684C2.64829 18.4684 2.3215 18.449 2 18.4112C3.81626 19.5765 5.97252 20.2547 8.28909 20.2547C15.8378 20.2547 19.9644 14.0026 19.9644 8.58029C19.9644 8.40412 19.96 8.2262 19.9521 8.05003C20.7536 7.47045 21.4495 6.74905 21.9982 5.92724L22 5.9246Z"
					fill="#fff"
				></path>{" "}
			</g>
		</svg>
	);

	useEffect(() => {
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);

		getAPI(`/coachs/${coachParam}`).then((dat) => {
			setCoach(dat);
		});
	}, []);

	useEffect(() => {
		if (!coach) return;
		if (coach.disabled === 1) {
			navigate("/coachs");
			return;
		}

		let langs = coach.language.split(",").map((v) => `${v}.png`.trim());
		setLanguages(langs);

		switch (coach.niveau) {
			case 1:
				setNiveau("Débutant à Intermédiaire");
				break;
			case 2:
				setNiveau("Débutant à Avancé");
				break;
			case 3:
				setNiveau("Débutant à Expert");
				break;
			default:
				setNiveau("Débutant");
				break;
		}

		let tempO = reseaux;
		if (coach.discord) {
			tempO["discord"] = coach.discord;
		}
		if (coach.youtube) {
			tempO["youtube"] = coach.youtube;
		}
		if (coach.twitter) {
			tempO["twitter"] = coach.twitter;
		}
		if (coach.twitch) {
			tempO["twitch"] = coach.twitch;
		}
		setReseaux(tempO);

		let li = () => {
			let r = {};
			Object.keys(reseaux).forEach((v, i) => {
				r[v] = [reseaux[v]];
				if (v === "discord") r[v][1] = discordIcon;
				if (v === "youtube") r[v][1] = youtubeIcon;
				if (v === "twitter") r[v][1] = twitterIcon;
				if (v === "twitch") r[v][1] = twitchIcon;
			});
			return r;
		};
		setLinks(li());
		setIsLoading(false);
	}, [coach]);

	useLayoutEffect(() => {
		if (isLoading) return;
		if (
			coach &&
			document.getElementById("description-container").children.length ===
				0
		)
			document
				.getElementById("description-container")
				.appendChild(
					new DOMParser().parseFromString(
						coach.description,
						"text/html",
					).body,
				);
	}, [coach, isLoading]);

	if (isLoading)
		return (
			<span className="coach">
				<div className="neon left-[-1000px] top-[-350px]"></div>
				<div className="neon right-[-1000px]"></div>
				<div className="loader">
					<CircularProgress color="success" />
				</div>
			</span>
		);

	if (!coach.id) {
		return <Navigate to="/coachs" />;
	}

	return (
		<span className="coach">
			{/* <div className='neon left-[-1000px] top-[-350px]'></div>
			<div className='neon right-[-1000px]'></div> */}

			<div className="flex items-center w-full mx-[150px]">
				<p className="opacity-60">
					Coachs &nbsp; &gt; &nbsp; {coach.nom} &nbsp; &gt; &nbsp;
				</p>
				<p>{coach.pseudo}</p>
			</div>

			<div className="main-container flex w-full px-[150px] justify-start mt-[40px] gap-16">
				<div className="left-container flex flex-col">
					{/* AVATAR */}
					{
						<div className="flex bg-no-repeat object-cover aspect-square border-1 h-[252px] w-[252px] rounded-md border-white/20">
							<Avatar
								image={coach.image}
								size={250}
								radius={"rounded-md"}
							/>
						</div>
					}

					{/* BADGES */}
					<div className="mt-3.5 font-bold flex-wrap">
						{/* RESEAUX */}
						<div className="flex gap-2">
							{Object.values(links).map((v, i, a) => {
								return (
									<Link key={i} to={v[0]} target="_blank">
										<div className="rounded bg-[#1B1B1B] p-[11px] hover:opacity-70 cursor-pointer ease-in-out duration-200 border-2 border-white/10">
											{v[1]}
										</div>
									</Link>
								);
							})}
						</div>
						{/* INFOS COACHING */}
						<div className="flex gap-2 mt-2">
							{" "}
							<div className="rounded bg-[#1B1B1B] p-[8px] text-center border-2 border-white/10">{`${coach.heures}h de coaching`}</div>
							<div className="rounded bg-[#1B1B1B] p-[8px] text-center border-2 border-white/10">{`${coach.seances} séances`}</div>
						</div>
						{/* LANGUAGES */}
						<div className="flex gap-2 mt-2">
							{languages.map((v, i) => {
								return (
									<img
										key={i}
										src={require(
											`../assets/languages/${v}`,
										)}
										alt={v}
									/>
								);
							})}
						</div>
						{/* PLATEFORMES */}
						<div className="flex gap-2 mt-2">
							{coach.plateforme.split(",").map((v, i) => {
								return (
									<div
										className="rounded bg-[#1B1B1B] p-[11px] border-2 border-white/10"
										key={i}
									>
										{v.trim()}
									</div>
								);
							})}
						</div>
						{/* NIVEAU */}
						<div className="flex flex-col gap-2 mt-8">
							<h4 className="mb-2 text-lg">Niveau de coaching</h4>
							<div className="rounded bg-[#1B1B1B] p-[11px] text-center border-2 border-white/10">
								{niveau}
							</div>
						</div>
						{/* CONTACTER */}
						{auth.user.id && auth.user.id != coach.id && (
							<div
								className="rounded bg-[#1B1B1B] p-[11px] text-center border-2 border-white/10 flex justify-center items-center gap-2 mt-10 hover:cursor-pointer hover:opacity-75 duration-200"
								onClick={async (e) => {
									await postAPI(`/user/addContact`, {
										contact: coach.id,
									}).then((res) => {
										if (res.success) {
											navigate(`/chat`);
										} else {
											enqueueSnackbar(
												`Une erreur s'est produite dans l'ajout du contact`,
												{ variant: "error" },
											);
										}
									});
								}}
							>
								<QuestionAnswerRoundedIcon fontSize="medium" />{" "}
								Contacter {coach.pseudo}
							</div>
						)}
					</div>
				</div>

				<div className="mid-container flex flex-col items-start">
					<div className="w-full">
						{/* PRESENTATION */}
						<div className="w-full flex items-center gap-x-2 mb-[14px]">
							<h3 className="text-3xl font-bold tracking-wide font-sf-pro-e ">
								{coach.pseudo}
							</h3>
							{coach.verified ? <VerifiedIcon /> : null}
						</div>
						<div className="min-w-fit leading-[24px] text-lg">
							<div
								className="text-justify max-w-3xl"
								id="description-container"
							></div>
						</div>

						<div className="flex items-baseline gap-14">
							{/* AVIS */}
							<div className="flex flex-col flex-wrap flex-1 gap-4 mt-8 min-w-fit">
								<h3 className="text-2xl font-sf-pro-e font-bold">
									Avis de {coach.pseudo}
								</h3>
								<Review coach={coach.id} />
							</div>

							{/* FORMULES */}
							<div className="max-w-fit">
								<h3 className="text-2xl font-sf-pro-e font-bold mb-6">
									Pack disponibles
								</h3>
								<Formules coach={coach.id} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</span>
	);
};

export default Coach;
