import React from 'react';

const Progressbar = (props) => {
	return (
		<div className='bg-[#0E0E0F] w-full h-1 rounded-full max-w-[200px]'>
			<div className={`bg-primary h-1 rounded-full`} style={{width: `${props.value}%`}}></div>
		</div>
	);
}

export default Progressbar;