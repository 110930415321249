import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { enqueueSnackbar, useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../auth";
import { getAPI, postAPI } from "../utils/functions";
import { useStripeConnect } from "../utils/useStripeConnect";
import {
	ConnectAccountOnboarding,
	ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import {
	ErrorRounded,
	InfoRounded,
	VerifiedRounded,
} from "@mui/icons-material";
import moment from "moment";

const Wallet = () => {
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const navigate = useNavigate();
	const [wallet, setWallet] = useState(null);
	const [transferLoading, setTransferLoading] = useState(false);
	const [transferHist, setTransferHist] = useState([]);

	const [accountCreatePending, setAccountCreatePending] = useState(false);
	const [onboardingExited, setOnboardingExited] = useState(false);
	const [error, setError] = useState(false);
	const [connectedAccountId, setConnectedAccountId] = useState(
		wallet?.connected_account,
	);
	const stripeConnectInstance = useStripeConnect(connectedAccountId);

	useEffect(() => {
		auth.authenticate().then((r) => setUser(r));
	}, []);

	useEffect(() => {
		if (user) {
			fetchData().then((_) => setLoading(false));
		}
	}, [user]);

	const fetchData = async () => {
		await getAPI(`/user/getWallet`).then(async (dat) => {
			setWallet(dat);
		});
		await postAPI(`/getTransferHist`, {
			coach_id: auth.user.id,
		}).then(async (dat) => {
			setTransferHist(dat);
		});
	};

	useEffect(() => {
		if (!onboardingExited) return;
		postAPI(`/updateWallet`, {
			...wallet,
			connected_account: connectedAccountId,
		}).then((res) => {
			if (res.affectedRows >= 1) {
				enqueueSnackbar(`Votre compte a été vérifié avec succès !`, {
					variant: "success",
				});
			}
		});
	}, [onboardingExited]);

	const askWalletTransfer = async (e) => {
		setTransferLoading(true);
		await postAPI("/wallet-transfer", {
			coach_id: wallet.coach_id,
			wallet_id: wallet.wallet_id,
		})
			.then(async (data) => {
				if (data.error_message) {
					enqueueSnackbar(`${data.error_message}`, {
						variant: "error",
					});
				} else if (data.success_message) {
					enqueueSnackbar(`${data.success_message}`, {
						variant: "success",
					});
				}
			})
			.catch((err) => {
				if (err)
					enqueueSnackbar(`Une erreur inattendue est survenue`, {
						variant: "error",
					});
			})
			.finally(() => setTransferLoading(false));
	};

	if (loading)
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Votre portefeuille
					</h3>
					<p className="text-white/75">
						Vous pouvez consultez ici vos gains et les réclamer pour
						qu'ils soient transférés vers un compte externe.
					</p>
				</div>

				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
			</div>
		);

	if (wallet) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Votre portefeuille
					</h3>
					<p className="text-white/75">
						Voici votre portefeuille. Vous pouvez consultez vos
						gains et les réclamer pour qu'ils soient transférés vers
						un compte externe.
					</p>
				</div>

				<div className="flex flex-col gap-6 w-full">
					<div className="bg-[#1B1B1B] border-2 border-white/10 rounded p-4 max-w-96">
						<div className="flex justify-between">
							<h3 className="opacity-75">Montant disponible</h3>
							<h3 className="font-sf-pro-e font-bold text-lg">
								{wallet.montant}€
							</h3>
						</div>
					</div>

					<div className="flex flex-col gap-2 opacity-75">
						<div className="flex items-center gap-1">
							<InfoRounded />
							<div>
								Veuillez noter que le virement peut prendre
								jusqu'à 15 jours pour être visible sur votre
								compte. Si au bout de ces 15 jours le virement
								n'a toujours pas été reçu, contactez
								l'assistance technique SkillUpNow.
							</div>
						</div>
						<div>
							{wallet.connected_account || onboardingExited ? (
								<div className="flex items-center gap-1">
									<VerifiedRounded /> Votre compte a bien été
									vérifié. Vous pouvez demander un retrait.
								</div>
							) : (
								<div className="flex items-center gap-1">
									<ErrorRounded /> Votre compte n'a pas encore
									été vérifié. Vous ne pouvez pas encore
									demander de retrait. Veuillez vérifier votre
									compte en cliquant sur le bouton ci-dessous.
								</div>
							)}
						</div>
						<div>
							{wallet.montant >= 20 ? (
								<div className="flex items-center gap-1">
									<VerifiedRounded /> Le solde de votre
									portefeuille est suffisant pour demander un
									retrait.
								</div>
							) : (
								<div className="flex items-center gap-1">
									<ErrorRounded /> Votre solde est insuffisant
									pour demander un retrait. La somme minimum
									nécessaire pour demander un retrait est de
									20€.
								</div>
							)}
						</div>
					</div>

					{wallet.montant >= 20 ? (
						(connectedAccountId && onboardingExited) ||
						wallet.connected_account ? (
							transferLoading ? (
								<div className="button-primary max-w-52 opacity-75">
									<CircularProgress
										color="success"
										size={24}
									/>
								</div>
							) : (
								<div
									className="button-primary max-w-52"
									onClick={askWalletTransfer}
								>
									Demander un retrait
								</div>
							)
						) : (
							<div className="flex flex-col gap-4 items-left">
								<div
									className="button-primary max-w-52"
									onClick={async () => {
										setAccountCreatePending(true);
										setError(false);
										postAPI("/connect-account").then(
											(json) => {
												setAccountCreatePending(false);
												const { account, error } = json;

												if (account) {
													setConnectedAccountId(
														account,
													);
												}

												if (error) {
													setError(true);
												}
											},
										);
									}}
								>
									Vérifier mes informations
								</div>
								{stripeConnectInstance && (
									<ConnectComponentsProvider
										connectInstance={stripeConnectInstance}
									>
										<ConnectAccountOnboarding
											onExit={() =>
												setOnboardingExited(true)
											}
										/>
									</ConnectComponentsProvider>
								)}
								{error && (
									<p className="error">
										Oups... Nous avons eu un soucis.
										Veuillez réessayer plus tard.
									</p>
								)}
								{(connectedAccountId ||
									accountCreatePending ||
									onboardingExited) && (
									<div className="dev-callout">
										{/*{connectedAccountId && <p>L'ID de votre compte est : <code className="bold">{connectedAccountId}</code></p>}*/}
										{accountCreatePending && (
											<p className="mt-4 opacity-75">
												Vérification de votre compte ...
											</p>
										)}
										{/*{onboardingExited && <p>Votre compte a été vérifié !</p>}*/}
									</div>
								)}
							</div>
						)
					) : null}
				</div>

				<div className="flex flex-col gap-5 mt-10">
					<h2 className="font-bold text-2xl font-sf-pro-e">
						Historique des retraits
					</h2>
					{transferHist.length > 0 ? (
						<div className="flex flex-col">
							{transferHist.map((transfert, i) => {
								return (
									<div
										key={i}
										className={`${i === transferHist.length - 1 ? "" : "border-b border-white/25"} w-96 flex items-center justify-between py-4`}
									>
										<div className="flex flex-col">
											<h2 className="opacity-75">Date</h2>
											<h2>
												{moment(transfert.date).format(
													"LL",
												)}
											</h2>
										</div>
										<div className="flex flex-col items-end">
											<h2 className="opacity-75">
												Montant
											</h2>
											<h2>
												{transfert.montant_transfert}€
											</h2>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<div className="text-lg opacity-75 text-center mt-5">
							Vous n'avez effectué aucun retrait
						</div>
					)}
				</div>
			</div>
		);
	}
};

export default Wallet;
