import { enqueueSnackbar } from "notistack";

export const sendNotification = async (receiver, text, linkTo) => {
	await postAPI("/user/sendNotification", {
		receiver: receiver,
		text: text,
		linkTo: linkTo,
	});
};

/*export const sendMailNotification = async (to, subject, content) => {
	await postAPI("/sendMailNotification", {
		to: to,
		subject: subject,
		content: content,
	})
		.then((res) => {
			if (res.success)
				enqueueSnackbar(
					`Un mail a été envoyé pour réinitialiser votre mot de passe !`,
					{ variant: "success" },
				);
		})
		.catch((e) => {
			if (e) {
				enqueueSnackbar(
					`Une erreur s'est produite en chemin, réessayez plus tard...`,
					{ variant: "error" },
				);
			}
		});
};*/

export const sendReview = async (coach, user, note, message) => {
	await postAPI(`/user/postReview`, {
		coach: coach,
		note: note,
		message: message,
	}).then(() => {
		return enqueueSnackbar("Votre avis a été publié", {
			variant: "success",
		});
	});
};

export const postAPI = async (endpoint, body = {}) => {
	return new Promise(async (resolve, reject) => {
		await fetch(`${process.env.REACT_APP_API_URI}${endpoint}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
			body: JSON.stringify(body),
		})
			.then((response) =>
				response.json().then((data) => {
					if (data.jwt_error) logout(data.message);
					resolve(data);
				}),
			)
			.catch((err) => {
				console.error("Error in postApi : ", err.message);
				reject(err);
			});
	});
};

export const getAPI = async (endpoint) => {
	return new Promise(async (resolve, reject) => {
		await fetch(`${process.env.REACT_APP_API_URI}${endpoint}`, {
			method: "GET",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				response.json().then((data) => {
					if (data.jwt_error) logout(data.message);
					resolve(data);
				});
			})
			.catch((err) => {
				console.error("Error in getApi : ", err.message);
				reject(err);
			});
	});
};

const logout = async (reason) => {
	localStorage.removeItem("id");
	localStorage.removeItem("pseudo");
	localStorage.removeItem("image");
	localStorage.removeItem("discord");
	localStorage.removeItem("coach");
	await postAPI(`/logout`).then((data) => {
		if (data.success) {
			switch (reason) {
				case "jwt expired":
					window.location.replace("/login#jwt_expired");
					break;

				case "jwt must be provided":
					window.location.replace("/login#jwt_not_provided");
					break;

				default:
					break;
			}
			console.info(`[AUTH] => Unknown user logged out`);
		}
	});
};

export function toBase64(arr) {
	//arr = new Uint8Array(arr) if it's an ArrayBuffer
	return btoa(
		arr.reduce((data, byte) => data + String.fromCharCode(byte), ""),
	);
}
