import React, { useEffect, useLayoutEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Checkbox } from "./checkbox";
import { Link } from "react-router-dom";
import { getAPI, postAPI } from "./utils/functions";
import Avatar from "./utils/avatar";

const Coachs2 = () => {
	const [coachs, setCoachs] = useState([]);
	const [jeux, setJeux] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedGame, setSelectedGame] = useState("");
	const [certified, setCertified] = useState(false);
	const [name, setName] = useState("");
	const [minimumPrice, setMinimumPrice] = useState("");
	const [maximumPrice, setMaximumPrice] = useState("");
	const [minimumNote, setMinimumNote] = useState("");
	const [maximumNote, setMaximumNote] = useState("");

	useEffect(() => {
		getAPI(`/coachs`).then((dat) => {
			if (dat.length >= 0) {
				setCoachs(dat);
			}
		});

		getAPI(`/jeux`).then((dat) => {
			if (dat.length >= 0) {
				setJeux(dat);
			}
		});

		setLoading(false);
	}, []);

	/*	useEffect(() => {
		getAPI(`/coachs`).then((dat) => {
			if (dat.length >= 0) {
				let res = [];
				dat.forEach((c) => {
					if (
						c.pseudo.toUpperCase().includes(name.toUpperCase()) &&
						c.nom === selectedGame
					) {
						res.push(c);
					}
				});
			}
		});
	}, [
		name,
		selectedGame,
		certified,
		minimumPrice,
		maximumPrice,
		minimumNote,
		maximumNote,
	]);*/

	useLayoutEffect((params) => {
		let activeLink = document.querySelector(".active-link");
		activeLink
			? activeLink.classList.remove("active-link")
			: (activeLink = null);
		document.getElementById("coachs").classList.add("active-link");
	}, []);

	if (loading) {
		// RENDER LOADING
		return (
			<div className="w-full flex justify-center">
				<CircularProgress color="success" />
			</div>
		);
	}

	return (
		<div className="w-full items-center">
			{/* JEUX */}
			<div className="bg-[#1B1B1B] w-full flex h-[50px] items-center px-[150px] p-4 box-content flex-nowrap">
				{jeux.map((v, i) => {
					var imd = null;
					var im;
					if (v.image_jeux) {
						try {
							im = require(`../assets/jeux/${v.image_jeux}`);
						} catch (err) {
							if (err.code === "MODULE_NOT_FOUND") {
								console.log("module not found " + v.image_jeux);
								return null;
							}
						} finally {
							if (im) {
								imd = (
									<img
										className={
											"object-cover aspect-square box-content p-3.5 hover:bg-[#0E0E0F]/50 rounded ease-in-out duration-100 transition-all hover:cursor-pointer"
										}
										width={35}
										height={35}
										src={im}
									/>
								);
							}
						}
					}
					return (
						<div
							className="flex items-center justify-center px-2"
							key={i}
							onClick={(e) => {
								setSelectedGame(v.nom);
							}}
						>
							{imd}
						</div>
					);
				})}
			</div>

			{/* FILTERS
			<div className="mx-[150px] mt-4 border-2 border-white/10 rounded p-4 bg-[#1B1B1B] flex flex-col min-w-fit">
				<h3 className="opacity-70">
					Appliquer des filtres de recherche pour vous permettre de
					trouver le coach qui vous correspond.
				</h3>
				<div className="mt-2 flex items-baseline justify-between min-w-fit flex-wrap">
					<div className="flex flex-col gap-1 p-4">
						<h3 className="text-lg">Nom du coach</h3>
						<div className="input-container-small max-w-fit min-w-fit">
							<input
								className="m-0"
								value={name}
								placeholder="Rechercher"
								type="text"
								name="search_coach"
								id="search_coach"
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</div>
						<div className="flex items-center gap-2 w-fit mt-2">
							<Checkbox
								checked={certified}
								onChange={(e) => {
									setCertified(e.target.checked);
								}}
							/>
							<h4 className="whitespace-nowrap overflow-hidden">
								Coach vérifié
							</h4>
						</div>
					</div>

					<div className="flex flex-col gap-1 p-4">
						<h3 className="text-lg">Jeux</h3>
						<div className="input-container-small max-w-fit min-w-fit">
							<input
								className="m-0"
								value={selectedGame}
								placeholder="Rechercher"
								type="text"
								name="search_game"
								id="search_game"
								onChange={(e) => {
									setSelectedGame(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-1 min-w-fit p-4">
						<h3 className="text-lg">Prix</h3>
						<div className="flex items-center flex-wrap gap-2">
							<div className="input-container-small max-w-fit min-w-fit flex-nowrap gap-1">
								<input
									className="m-0"
									value={minimumPrice}
									placeholder="Min"
									type="text"
									name="min_prix"
									id="min_prix"
									size={5}
									onChange={(e) => {
										e.preventDefault();
										// Prix min
									}}
								/>
								€
							</div>
							à
							<div className="input-container-small max-w-fit min-w-fit flex-nowrap gap-1">
								<input
									className="m-0"
									value={maximumPrice}
									placeholder="Max"
									type="text"
									name="max_prix"
									id="max_prix"
									size={5}
									onChange={(e) => {
										e.preventDefault();
										// Prix max
									}}
								/>
								€
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-1 min-w-fit p-4">
						<h3 className="text-lg">Note</h3>
						<div className="flex items-center flex-wrap gap-2">
							<div className="input-container-small max-w-fit min-w-fit flex-nowrap gap-1">
								<input
									className="m-0"
									value={minimumNote}
									placeholder="Min"
									type="text"
									name="min_note"
									id="min_note"
									size={1}
									onChange={(e) => {
										e.preventDefault();
										// Note min
									}}
								/>
								<svg
									height="25px"
									width="25px"
									version="1.1"
									id="Capa_1"
									xmlns="http://www.w3.org/2000/svg"
									xmlnsXlink="http://www.w3.org/1999/xlink"
									viewBox="0 0 53.87 53.87"
									xmlSpace="preserve"
									fill="#ffffff"
									stroke="#ffffff"
									strokeWidth="0.00053867"
								>
									<g
										id="SVGRepo_bgCarrier"
										strokeWidth="0"
									></g>
									<g
										id="SVGRepo_tracerCarrier"
										strokeLinecap="round"
										strokeLinejoin="round"
									></g>
									<g id="SVGRepo_iconCarrier">
										{" "}
										<polygon
											style={{ fill: "#EFCE4A" }}
											points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "
										></polygon>{" "}
									</g>
								</svg>
							</div>
							à
							<div className="input-container-small max-w-fit min-w-fit flex-nowrap gap-1">
								<input
									className="m-0"
									value={maximumNote}
									placeholder="Max"
									type="text"
									name="max_note"
									id="max_note"
									size={1}
									onChange={(e) => {
										e.preventDefault();
										// Note max
									}}
								/>
								<svg
									height="25px"
									width="25px"
									version="1.1"
									id="Capa_1"
									xmlns="http://www.w3.org/2000/svg"
									xmlnsXlink="http://www.w3.org/1999/xlink"
									viewBox="0 0 53.87 53.87"
									xmlSpace="preserve"
									fill="#ffffff"
									stroke="#ffffff"
									strokeWidth="0.00053867"
								>
									<g
										id="SVGRepo_bgCarrier"
										strokeWidth="0"
									></g>
									<g
										id="SVGRepo_tracerCarrier"
										strokeLinecap="round"
										strokeLinejoin="round"
									></g>
									<g id="SVGRepo_iconCarrier">
										{" "}
										<polygon
											style={{ fill: "#EFCE4A" }}
											points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "
										></polygon>{" "}
									</g>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full flex justify-end">
					<button
						className="button-secondary w-fit"
						onClick={(e) => {
							setSelectedGame("");
							setCertified(false);
							setMaximumNote("");
							setMinimumNote("");
							setMaximumPrice("");
							setMinimumPrice("");
							setName("");
						}}
					>
						Supprimer les filtres
					</button>
				</div>
			</div>*/}

			{/* COACHS */}
			<div className="flex gap-36 flex-wrap mx-[150px] mt-20">
				{coachs.map((coach, i) => {
					/* GAME-BANNER */
					let imb = (
						<div className="bg-black/50 rounded h-[420px] w-[290px]"></div>
					);
					let im2;
					if (coach.banner_jeux) {
						try {
							im2 = require(
								`../assets/jeux/${coach.banner_jeux}`,
							);
						} catch (err) {
							if (err.code === "MODULE_NOT_FOUND") {
								console.log(
									"module not found " + coach.banner_jeux,
								);
								return null;
							}
						} finally {
							if (im2) {
								imb = (
									<img
										className={"object-cover rounded-md"}
										width={290}
										height={420}
										src={im2}
										alt={im2}
									/>
								);
							}
						}
					}

					const languages = coach.language
						.split(",")
						.map((v) => `${v}.png`.trim());

					return (
						<div className="relative" key={i}>
							<Link to={`/coachs/${coach.pseudo}`}>
								<div
									className={`shadow-lg w-[250px] h-[410px] rounded-md bg-[#1B1B1B] p-4 flex flex-col box-content gap-1 z-10 relative -mt-8 -ml-6 border-2 border-white/5 hover:-mr-2 hover:-mt-6 hover:-ml-4 hover:cursor-pointer duration-100 ease-in-out`}
								>
									<Avatar
										image={coach.image}
										size={250}
										radius={"rounded-md"}
									/>
									<div className="flex items-center">
										<h4 className="font-bold font-sf-pro-e text-xl">
											{coach.pseudo}
										</h4>
										{coach.verified ? (
											<div className="flex items-center scale-75 -mr-4 pl-px">
												<VerifiedIcon />{" "}
											</div>
										) : null}
									</div>

									<div className="flex flex-col">
										<h4 className="font-bold font-sf-pro-e text-l">
											{coach.nom}
										</h4>
										<div className="flex gap-2">
											{languages.map((h, k) => {
												return (
													<img
														key={k}
														src={require(
															`../assets/languages/${h}`,
														)}
														alt={k}
														className="scale-75 -mx-2"
													/>
												);
											})}
										</div>
									</div>

									{coach.note_coach !== 0 ? (
										<div className="flex gap-1 items-center">
											<h4 className="text-lg">
												{coach.note_coach.toFixed(1)}
												/5.0
											</h4>
											<svg
												height="25px"
												width="25px"
												version="1.1"
												id="Capa_1"
												xmlns="http://www.w3.org/2000/svg"
												xmlnsXlink="http://www.w3.org/1999/xlink"
												viewBox="0 0 53.87 53.87"
												xmlSpace="preserve"
												fill="#ffffff"
												stroke="#ffffff"
												strokeWidth="0.00053867"
											>
												<g
													id="SVGRepo_bgCarrier"
													strokeWidth="0"
												></g>
												<g
													id="SVGRepo_tracerCarrier"
													strokeLinecap="round"
													strokeLinejoin="round"
												></g>
												<g id="SVGRepo_iconCarrier">
													{" "}
													<polygon
														style={{
															fill: "#EFCE4A",
														}}
														points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "
													></polygon>{" "}
												</g>
											</svg>
										</div>
									) : null}

									<h4>
										À partir de{" "}
										{coach.meilleur_prix.toFixed(2)}€/h
									</h4>
								</div>
							</Link>

							<div className="absolute top-0 -left-2 flex h-[420px] w-[290px]">
								{imb}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default Coachs2;
