import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

import { useAuth } from "../auth";
import Dialog from "../utils/dialog";
import { getAPI, postAPI } from "../utils/functions";

const HistSeances = ({ user }) => {
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [seances, setSeances] = useState([]);
	const [dialogContent, setDialogContent] = useState(null);

	const fetchData = async () => {
		if (user) {
			await getAPI(`/user/seances/hist`).then(async (dat) => {
				setSeances(dat);
				setLoading(false);
			});
		}
	};

	useEffect(() => {
		fetchData();
	}, [user]);

	const handleCloseDialog = () => {
		setDialogContent(null);
	};

	const orderBy = (order) => {
		switch (order) {
			case "date":
				break;

			case "montant":
				break;

			default:
				break;
		}
	};

	if (loading)
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Historique des séances
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances passées
					</p>
				</div>

				<div className="flex items-center justify-center mt-8">
					<CircularProgress color="success" />
				</div>
			</div>
		);

	if (seances && seances.length > 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Historique des séances
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances passées
					</p>
				</div>

				<div
					className={`responsive-font border-2 border-white/25 bg-[#1B1B1B] rounded text`}
				>
					<div className="sticky grid grid-cols-7 grid-rows-${seances.length} gap-1 font-semibold p-1 border-b-2 border-white/25">
						<div
							className="p-2 w-fit rounded"
							onClick={() => {
								orderBy("date");
							}}
						>
							Date
						</div>
						<div className="p-2 w-fitrounded">
							{user.coach ? "Membre" : "Coach"}
						</div>
						<div className="p-2 w-fit rounded">Formule</div>
						<div
							className="p-2 w-fit rounded"
							onClick={() => {
								orderBy("montant");
							}}
						>
							Montant
						</div>
						<div className="p-2 w-fit rounded">ID de la séance</div>
						<div className="p-2 w-fit rounded">Status</div>
						<div className="p-2 w-fit rounded">Récapitulatif</div>
					</div>
					<div
						className={`relative grid grid-cols-7 grid-rows-${seances.length} mt-2 p-3`}
					>
						{Object.values(seances).map((s, i) => {
							let statusIcon;
							switch (s.status) {
								case "Validée":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#5E98FC"
											/>
										</svg>
									);
									break;

								case "Annulée":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#ED4337"
											/>
										</svg>
									);
									break;

								case "Refusée":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#ED4337"
											/>
										</svg>
									);
									break;

								case "Terminée":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#52fc1a"
											/>
										</svg>
									);
									break;

								case "En attente d'annulation":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#d1cb1d"
											/>
										</svg>
									);
									break;

								case "En attente de confirmation":
									statusIcon = (
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5"
												cy="5"
												r="5"
												fill="#d1cb1d"
											/>
										</svg>
									);
									break;

								default:
									break;
							}
							return (
								<React.Fragment key={i}>
									<div className="py-4">
										{moment(s.date).format("lll")}
									</div>
									{user.coach ? (
										<div className="py-4">
											{s.member.pseudo}
										</div>
									) : (
										<a href={`/coachs/${s.coach.pseudo}`}>
											<div className="py-4">
												{s.coach.pseudo}
											</div>
										</a>
									)}
									<div className="py-4">
										{s.formule.titre}
									</div>
									<div className="py-4">
										{s.formule.prix.toFixed(2)}€
									</div>
									<div className="py-4">{s.id}</div>
									<div className="flex items-center gap-3 py-4">
										<div className="flex items-center gap-1">
											{statusIcon} {s.status}
										</div>
									</div>
									<div
										className="py-4 cursor-pointer"
										onClick={(e) => {
											setDialogContent(s);
										}}
									>
										<RemoveRedEyeRoundedIcon />
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>

				{dialogContent && (
					<Dialog
						content={dialogContent}
						handleClose={handleCloseDialog}
						refreshFunction={fetchData}
					/>
				)}
			</div>
		);
	} else if (user && seances && seances.length === 0) {
		return (
			<div className="flex flex-col gap-10 w-full h-full">
				<div className="leading-[20px]">
					<h3 className="font-bold font-sf-pro-e text-2xl">
						Historique des séances
					</h3>
					<p className="text-white/75">
						Consultez ici toutes les séances passées
					</p>
				</div>

				<div
					className={`border-2 border-white/10 bg-[#1B1B1B] rounded relative p-4`}
				>
					<div className="text-center">
						{!user.coach ? (
							<h3 className="text-xl">
								Il semblerait que vous n'ayez demandé aucune
								séance.{" "}
								<Link to={"/coachs"} className="text-primary">
									Trouvez votre coach
								</Link>{" "}
								dès maintenant !
							</h3>
						) : (
							<h3 className="text-xl">
								Il semblerait que vous n'ayez participé à aucune
								séance... Patience.
							</h3>
						)}
					</div>
				</div>
			</div>
		);
	}
};

export default HistSeances;
